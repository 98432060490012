import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import SelectSummary from "../summary/Select";
import MetaText from "../MetaText";

dayjs.extend(customParseFormat);

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

const dayTransform = (value) => dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD");

export default {
    groups: [
        {
            title: "Rückruf",
            description: "Wie können wir Sie am besten erreichen?",
            fields: [
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "customer_data.phone",
                    validation: Yup.string().default("").required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        { value: "08:00", name: "08:00" },
                        { value: "08:30", name: "08:30" },
                        { value: "09:00", name: "09:00" },
                        { value: "09:30", name: "09:30" },
                        { value: "10:00", name: "10:00" },
                        { value: "10:30", name: "10:30" },
                        { value: "11:00", name: "11:00" },
                        { value: "11:30", name: "11:30" },
                        { value: "12:00", name: "12:00" },
                        { value: "12:30", name: "12:30" },
                        { value: "13:00", name: "13:00" },
                        { value: "13:30", name: "13:30" },
                        { value: "14:00", name: "14:00" },
                        { value: "14:30", name: "14:30" },
                        { value: "15:00", name: "15:00" },
                        { value: "15:30", name: "15:30" },
                        { value: "16:00", name: "16:00" },
                        { value: "16:30", name: "16:30" },
                    ],
                    placeholder: "Uhrzeit",
                    label: "von",
                    name: "from",
                    size: "half",
                    submitKey: "customer_data.timefrom",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .oneOf(
                            [
                                "08:00",
                                "08:30",
                                "09:00",
                                "09:30",
                                "10:00",
                                "10:30",
                                "11:00",
                                "11:30",
                                "12:00",
                                "12:30",
                                "13:00",
                                "13:30",
                                "14:00",
                                "14:30",
                                "15:00",
                                "15:30",
                                "16:00",
                                "16:30",
                            ],
                            "Ungültiger Wert"
                        )
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        { value: "08:00", name: "08:00" },
                        { value: "08:30", name: "08:30" },
                        { value: "09:00", name: "09:00" },
                        { value: "09:30", name: "09:30" },
                        { value: "10:00", name: "10:00" },
                        { value: "10:30", name: "10:30" },
                        { value: "11:00", name: "11:00" },
                        { value: "11:30", name: "11:30" },
                        { value: "12:00", name: "12:00" },
                        { value: "12:30", name: "12:30" },
                        { value: "13:00", name: "13:00" },
                        { value: "13:30", name: "13:30" },
                        { value: "14:00", name: "14:00" },
                        { value: "14:30", name: "14:30" },
                        { value: "15:00", name: "15:00" },
                        { value: "15:30", name: "15:30" },
                        { value: "16:00", name: "16:00" },
                        { value: "16:30", name: "16:30" },
                    ],
                    placeholder: "Uhrzeit",
                    label: "bis",
                    name: "until",
                    size: "half",
                    submitKey: "customer_data.timeuntil",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .oneOf(
                            [
                                "08:00",
                                "08:30",
                                "09:00",
                                "09:30",
                                "10:00",
                                "10:30",
                                "11:00",
                                "11:30",
                                "12:00",
                                "12:30",
                                "13:00",
                                "13:30",
                                "14:00",
                                "14:30",
                                "15:00",
                                "15:30",
                                "16:00",
                                "16:30",
                            ],
                            "Ungültiger Wert"
                        )
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        { value: "contract", name: "Fragen zu meinem Vertrag" },
                        { value: "bill", name: "Fragen zu meiner Rechnung" },
                        { value: "advice", name: "Tarifberatung" },
                        { value: "change", name: "Abschlagsänderung" },
                        { value: "rederister", name: "An-/Abmelden einer Verbrauchsstelle" },
                        { value: "others", name: "Sonstiges" },
                    ],
                    placeholder: "Bitte auswählen",
                    label: "Anliegen",
                    name: "subject",
                    submitKey: "customer_data.subject",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte auswählen")
                        .oneOf(
                            [
                                "contract",
                                "bill",
                                "advice",
                                "change",
                                "rederister",
                                "others",
                            ],
                            "Ungültiger Wert"
                        ),
                },
            ],
        },
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Input,
                    label: "Vertragskontonummer",
                    name: "contractnumber",
                    submitKey: "customer_data.contractnumber",
                    placeholder: "Siehe Rechnung",
                    validation: Yup.string()
                        .default("")
                        .matches(/^[0-9]+$/, "Vertragsnummer muss nur aus Ziffern bestehen")
                        .min(10, 'Vertragsnummer muss zehnstellig sein')
                        .max(10, 'Vertragsnummer muss zehnstellig sein'),
                },
                {
                    type: Select,
                    options: [
                        { value: "female", name: "Frau" },
                        { value: "male", name: "Herr" },
                        { value: "other", name: "Divers" },
                        { value: "spouses", name: "Eheleute" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(["female", "male", "other", "spouses"], "Ungültiger Wert"),
                },
                {
                    type: Input,
                    label: "Titel",
                    name: "title",
                    submitKey: "customer_data.title",
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string().default("").required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string().default("").required("Bitte ausfüllen"),
                },
            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
            ],
        },
    ],
    submitUrl: "/api/form",
};
