<template>
    <div  class="container mx-auto px-4 xl:px-0 w-full flex flex-col lg:flex-row lg:space-x-4 items-start">
        <div  class="flex flex-col space-y-8 grow mx-auto sm:px-4 md:px-0">
            <div class="rounded-lg overflow-hidden"
                 :class="index === 0 ? 'bg-white shadow-xl' : 'bg-transparent'"
                 v-for="(product, index) in foundProducts">
                <div class="relative bg-red-600 py-2 shadow-2xl shadow--group-hover:bg-gray-800/15" v-if="index === 0">
                    <span class="inline-flex px-6 py-2 text-xl font-bold tracking-wider text-white">Unser beliebtester Tarif:</span>
                </div>
                <div class="flex px-6 py-6">
                    <div class="grow">
                        <h3 class="text-2xl font-semibold text-gray-400 flex flex-wrap sm:flex-nowrap items-center"
                            id="tier-growth">
                            <img className="h-6 mr-2 w-auto" src="/static/img/logo_no_typo.svg" alt="Stadtwerke Herne Logo"
                                 title="Stadtwerke Herne Logo"/>
                            <span class="text-xs lg:text-base translate-y-[4px]">{{ product.name }}</span>
                        </h3>
                        <div class="border-t-2 text-xs md:text-base border-gray-300 my-2 text-gray-300">
                            Ehrlich, fair & bodenständig.
                        </div>
                        <Checklist :items="product.checklist" class="space-y-4"/>
                    </div>
                    <div class="text-right md:pl-8 flex flex-col space-y-12 text-gray-600">
                        <div v-if="product.price">
                            <span class="text-2xl md:text-4xl font-system">{{ moneyFormat(product.price.monthlyGross) }}</span><br>
                            <span>pro Monat</span>
                        </div>
                        <div v-if="product.bonusWorld">
                            <div class="text-red-600 font-bold">Inklusive Bonuswelt-Card</div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end px-4 pb-4 md:pb-0">
                    <Button :glow="index === 0" :inset="index > 0"
                            @click="addAnalyticsEventBeforeCheckout( 'add_to_cart', index)"
                            :href="`/Checkout?postcode=${postcode}&consumption=${consumption}&product=${product.id}`"
                           >
                        Jetzt bestellen
                    </Button>
                </div>
            </div>
        </div>
        <div class="flex flex-col mx-auto mb-4 lg:mb-0 space-y-8 lg:max-w-[400px] sm:px-4 md:px-8 lg:px-0">
            <div class="rounded-lg shadow-xl bg-white py-8 px-6 text-gray-600 flex flex-col space-y-4">
                <div class="border-b border-gray-600 text-xl">Mein persönliches Produkt</div>
                <Input readonly disabled label="Ihre Postleitzahl" v-model="postcode"/>
                <ConsumptionPicker :subtype="type" v-model="consumption" label="Ihr Verbrauch / Jahr"></ConsumptionPicker>
            </div>

            <p class="text-sm text-gray-600">
                Aktuelle Preise für den angegebenen Jahresverbrauch inkl. Umsatzsteuer und gesetzlicher Abgaben. Der
                Grundpreis wird tagesgenau auf den jeweiligen Abrechnungszeitraum umgerechnet.
            </p>

            <div
                class="rounded-lg shadow-xl bg-[url('/bonuswelten_tile_bg.png')] bg-cover bg-center p-8 text-gray-600 flex flex-col space-y-4">
                <h4 class="text-2xl mr-16 font-bold">Profitieren Sie von der Stadtwerke Herne Bonuswelt!</h4>
                <ul role="list" class="space-y-4 mr-16">
                    <li class="flex items-start">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M5 13l4 4L19 7"></path>
                            </svg>
                        </div>
                        <p class="ml-3 text-base font-medium text-gray-500">Exklusive Angebote und einmalige Events</p>
                    </li>
                    <li class="flex items-start">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M5 13l4 4L19 7"></path>
                            </svg>
                        </div>
                        <p class="ml-3 text-base font-medium text-gray-500">Über 260 Partner in Herne</p></li>
                    <li class="flex items-start">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M5 13l4 4L19 7"></path>
                            </svg>
                        </div>
                        <p class="ml-3 text-base font-medium text-gray-500">Nutzerfreundliche App</p></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import Button from "./Button";
import Input from "./Input";
import ConsumptionPicker from "./ConsumptionPicker";
import {
    queryBonuswelten,
    queryBusiness,
    queryConsumption,
    queryEco,
    queryPostcode,
    queryProducts,
    queryType
} from './domain/queryParams';

import {useCheckoutStore} from "./stores/checkout";
import {moneyFormat} from "./domain/utils";
import Checklist from "./Checklist";
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {storeToRefs} from "pinia";

import {debouncedWatch} from "@vueuse/core/index";
import {addAnalyticsEventBeforeCheckout} from "./domain/steps";

const checkoutStore = useCheckoutStore();

let {consumption, bonuswelt, business, eco, postcode, priceDataResponse, products, type} = storeToRefs(checkoutStore);

defineComponent({
    components: {
        Button,
        Input,
        ConsumptionPicker,
        Checklist
    },
})

eco.value = queryEco;
business.value = queryBusiness;
bonuswelt.value = queryBonuswelten;
type.value = queryType;
products.value = queryProducts;
postcode.value = queryPostcode;
consumption.value = queryConsumption;
products.value = queryProducts.split(',');
checkoutStore.fetchPriceData();


debouncedWatch([consumption], () => {

    checkoutStore.fetchPriceData();

}, {
    debounce: 200
})

const foundProducts = computed( () => {
    if(!priceDataResponse.value) {
        addAnalyticsEventBeforeCheckout('view_item_list')
    }
    return Object.values(priceDataResponse.value ?? [])?.sort((a, b) => a.order - b.order).sort((a, b) => a.bonusWorld - b.bonusWorld)
})


</script>

