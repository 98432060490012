<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <SwitchGroup as="div" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col mr-2 md:mr-4">
          <SwitchLabel as="span" class="text-base md:text-lg font-medium text-gray-900" passive>{{
                  label
              }}</SwitchLabel>
          <SwitchDescription v-html="description" as="span" class="text-base text-gray-500 desclink"></SwitchDescription>
            <div v-if="props.error" class="text-sm text-red-600">
                {{ props.error }}
            </div>
        </span>
        <Switch v-model="value"
                :disabled="readonly || disabled"
                :class="[disabled && 'opacity-70 grayscale-[50%]', value ? 'bg-green-600' : 'bg-gray-200', 'z-auto relative inline-flex flex-shrink-0 h-8 w-16 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span class="sr-only">{{ label }}</span>
            <span
                :class="[value ? 'translate-x-8' : 'translate-x-0', 'pointer-events-none relative inline-block h-7 w-7 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
              <span
                  :class="[value ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']"
                  aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <span
                  :class="[value ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']"
                  aria-hidden="true">
                <svg class="h-3 w-3 text-green-600" fill="currentColor" viewBox="0 0 12 12">
                  <path
                      d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
                </svg>
              </span>
            </span>
        </Switch>
    </SwitchGroup>
</template>

<script setup>
import {computed, onMounted} from 'vue'
import {Switch, SwitchDescription, SwitchGroup, SwitchLabel} from '@headlessui/vue'

const props = defineProps({
    label: String,
    description: String,
    readonly: {
        type: Boolean,
        default: false
    },
    default: Boolean,
    disabled: {
        type: Boolean,
        default: false
    },
    error: String,
    modelValue: {
        default: false,
        type: Boolean
    }
})

const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return !!props.modelValue
    },
    set(value) {
        if (!props.readonly && !props.disabled) {
            emit('update:modelValue', value)
        }
    }
})

onMounted(() => {
    if(props.default) {
        value.value = props.default
    }
})
</script>

<style>
.desclink a {
    color: #e50a1e;
} .desclink a:hover {
    color: #f87171;
}
</style>
