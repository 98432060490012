import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Address from "../fields/Address";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import Switch from "../fields/Switch";
import Text from "../Text";
import MetaText from "../MetaText";
import YesNo from "../summary/YesNo";
import Iban from "../Iban";

dayjs.extend(customParseFormat);

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

const dayYupTransform = (value) => {
    value = value.trim();
    if(!value) return null;
    if(value === "__.__.____") return null;
    return value;
}

const dayTransform = (value) => {
    if(!value) return null;
    value = value.trim();

    if(value === "__.__.____") return null;
    return dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
}

export default {
    groups: [
        {
            title: "Persönliche Angaben",
            description:
                "",
            fields: [
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    submitKey: "firstname",
                    placeholder: "",
                    disabled: true,
                    validation: Yup.string().nullable().default("")
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    submitKey: "lastname",
                    placeholder: "",
                    disabled: true,
                    validation: Yup.string().nullable().default("")
                },
                {
                    type: Input,
                    label: "Vertragsnummer",
                    name: "account",
                    submitKey: "token",
                    placeholder: "",
                    disabled: true,
                    validation: Yup.string().nullable().default(""),
                    hidden: true
                },
                {
                    type: Input,
                    label: "",
                    name: "mailDocumentId",
                    submitKey: "mailDocumentId",
                    hidden: true
                },
                {
                    type: Input,
                    label: "",
                    name: "forwardPageId",
                    submitKey: "forwardPageId",
                    hidden: true
                },
                {
                    type: Input,
                    label: "",
                    name: "contract_extension_folder",
                    submitKey: "contract_extension_folder",
                    hidden: true
                },
                {
                    type: Input,
                    label: "Vertragskontonummer",
                    name: "account",
                    submitKey: "account",
                    placeholder: "",
                    disabled: true
                },
                {
                    type: Input,
                    label: "Zählernummer",
                    name: "meterNumber",
                    submitKey: "meterNumber",
                    placeholder: "",
                    disabled: true
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "phone",
                    validation: Yup.string().nullable().default(""),
                },
                {
                    type: Input,
                    subtype: 'email',
                    label: 'E-Mail',
                    name: 'email',
                    submitKey: 'email',
                    validation: Yup.string()
                        .default('')
                        .email('Dies ist keine gültige E-Mail Adresse')
                        .required('Bitte ausfüllen')
                },
                {
                    type: Input,
                    label: 'Geburtstag',
                    name: 'birthday',
                    mask: {
                        mask: Date,
                        min: new Date(1900, 0, 1),
                        max: new Date(),
                        lazy: false
                    },
                    submitKey: 'birthday',
                    submitTransform: dayTransform,
                    validation: Yup.string().nullable()
                        .transform(dayYupTransform)
                        .default(null)
                        .matches(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, 'Bitte ein gültiges Datum eingeben')
                },
                {
                    type: Input,
                    label: "Ihr Produkt",
                    name: "tariffType",
                    submitKey: "tariffType",
                    placeholder: "",
                    disabled: true,
                    validation: Yup.string().nullable().default("")
                },
                {
                    type: Switch,
                    label: 'Jahresvorausleistung',
                    description: 'Bei der Vorauszahlung bezahlen Sie die gesamte Summe für ein Jahr in einem Betrag. Ihr Vorteil: Wir gewähren Ihnen 2% Zinsen auf den Gesamtbetrag.',
                    name: 'JVL',
                    submitKey: "JVL",
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Switch,
                    label: 'Ökostrom',
                    conditions: (values) =>values.tariffType && values.tariffType?.includes('Strom'),
                    description: 'Ich bestelle zusätzlich zertifizierten Ökostrom für 1 Euro brutto Aufpreis im Monat',
                    name: 'eco',
                    submitKey: "eco",
                    validation: Yup.boolean().default(false),
                }
            ],
        },
        {
            title: "Zahlungsinformationen",
            hideInSummary: true,
            fields: [
                {
                    type: Select,
                    options: [
                        {value: 'sepa', name: 'Lastschrift'},
                        {value: 'transfer', name: 'Überweisung'},
                        {value: 'previous-sepa', name: 'Bitte vorhandenes SEPA-Mandat verwenden'}
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Zahlungsmethode",
                    name: "paymentType",
                    submitKey: "payment_data.type",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default('')
                        .required('Bitte ausfüllen')
                        .oneOf(['sepa', 'transfer', 'previous-sepa'], 'Ungültiger Wert')
                },
                {
                    type: Input,
                    label: 'Kontoinhaber',
                    name: 'accountHolder',
                    submitKey: 'payment_data.accountHolder',
                    conditions: (values) => values.paymentType === 'sepa',
                    validation: Yup.string()
                        .required('Bitte ausfüllen')
                },
                {
                    type: Iban,
                    label: 'IBAN',
                    name: 'iban',
                    mask: {
                        mask: 'aa00 0000 0000 0000 0000 0000 0000 0000 00'
                    },
                    submitKey: 'payment_data.iban',
                    placeholder: "DEXX XXXX XXXX XXXX XXXX XX",
                    conditions: (values) => values.paymentType === 'sepa',
                    validation: Yup.string().nullable()
                        .required('Bitte ausfüllen')
                },
                {
                    type: Input,
                    label: 'BIC',
                    name: 'bic',
                    submitKey: 'payment_data.bic',
                    placeholder: "XXXX XX XX XXX ",
                    conditions: (values) => values.paymentType === 'sepa',
                    validation: Yup.string()
                        .default('')
                },
            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
                {
                    type: Switch,
                    name: 'customerDisclaimer',
                    label: 'Widerrufsbelehrung',
                    submitKey: ['privacy_data.isRightOfWithdrawalAccepted'],
                    description: "Ich habe die Widerrufsbelehrung zur Kenntnis genommen und akzeptiere diese. Meinen schriftlichen Widerruf kann ich über das "  + "<a href=\'/PDFs/Bestellung/Widerrufsformular.pdf\' target=\'_blank\'>Widerrufsformular</a>. " + "an die Stadtwerke Herne senden.",
                    validation: Yup.boolean()
                        .oneOf([true], 'Bitte akzeptieren Sie die Widerrufsbelehrung')
                        .required('Bitte akzeptieren Sie die Widerrufsbelehrung')
                },
                {
                    type: Switch,
                    name: 'advertisingTerm',
                    label: 'Werbeeinwilligung',
                    submitKey: 'privacy_data.isAdvertisingTermsAccepted',
                    description: 'Ja, ich bin damit einverstanden, dass die Stadtwerke Herne AG mich über ihre Produkte der Strom- und Erdgasversorgung sowie Energiedienstleistungen informiert. Dazu darf die Stadtwerke Herne AG mich per kontaktieren:',
                },
                {
                    type: Switch,
                    name: 'advertisingPhone',
                    label: 'Telefon',
                    conditions: (values) => values.advertisingTerm === true,
                    submitKey: 'privacy_data.isAdvertisingPhoneAccepted',
                    description: '(bitte Zutreffendes ankreuzen)',
                },
                {
                    type: Switch,
                    name: 'advertisingMail',
                    label: 'E-Mail',
                    conditions: (values) => values.advertisingTerm === true,
                    submitKey: 'privacy_data.isAdvertisingMailAccepted',
                    description: '(bitte Zutreffendes ankreuzen)',
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "advertisingEmail",
                    submitKey: "advertisingEmail",
                    conditions: (values) => values.advertisingMail === true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: MetaText,
                    title: "Einwilligung",
                    description: "Diese Einwilligung kann ich jederzeit widerrufen, z. B. per Brief an die Stadtwerke Herne AG, Grenzweg 18, 44623 Herne oder per E-Mail kundenservice@stadtwerke-herne.de" +
                        " Informationen dazu, wie wir Ihre personenbezogenen Daten verarbeiten, finden Sie in der " + "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                }
            ],
        },
    ],
    submitUrl: "/api/contract-extension",
};
