<template>
    <span>{{ selectedValue }}</span>
</template>

<script setup>
import {asyncComputed} from "@vueuse/core/index";

const props = defineProps({
    value: Boolean,
    field: Object
})

const selectedValue = asyncComputed(async () => (await props.field.options).find(o => o.value === props.value)?.name || '-')
</script>
