<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-8" v-for="group in groups">
        <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ group.title }}
            </h3>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div
                    v-for="field in getSummaryFields(group, formStore.$state)"
                    class="py-4 sm:py-5"
                    :class="{ 'sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6': field.label }"
                >
                    <dt class="text-sm font-medium text-gray-500" v-if="field.label">
                        {{ field.label }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <component :is="field.summary || Text" :value="formStore.$state[field.name]" :field="field" :options="field.options"></component>
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script setup>
import { areConditionsFulfilled, steps } from './domain/steps';
import { computed } from "vue";
import { useFormStore } from "./stores/form";
import Text from './summary/Text';
import { useCheckoutStore } from "./stores/checkout";
import {storeToRefs} from "pinia";

const formStore = useFormStore("checkout")();

const groups = computed(() => steps.map(step => step.groups).flat().filter(group => !group.hideInSummary));

const getSummaryFields = (group, values) => {
    return group.fields.filter(field => !field.hideInSummary).filter(field => areConditionsFulfilled(field, values));
};

const checkoutStore = useCheckoutStore();

let {assets} = storeToRefs(checkoutStore);

const props = defineProps({
    value: Array
})
</script>
