<template>
    <div class="col-span-2 rounded-lg overflow-hidden mb-8 transition-all"
         :class="checkIsAddonActive(addon.id) ? 'shadow-lg' : 'shadow-sm'" v-for="addon in productAddons">
        <div class="flex justify-between items-center text-center bg-gray-50 rounded-t-lg border px-4 py-2"
             :class="checkIsAddonActive(addon.id) ? 'bg-green-50 border-green-600/30' : ''"
        >
            <div class="sm:flex items-center">
                <div class="font-medium text-lg" :class="checkIsAddonActive(addon.id) ? 'text-green-600' : 'text-gray-900'">
                    {{ addon.name }}
                </div>
                <span class="hidden sm:inline sm:mx-1" :class="checkIsAddonActive(addon.id) ? 'text-gray-500/50' : 'text-gray-500'" aria-hidden="true">·</span>

                <div class="text-black/40 text-sm">
                    {{ moneyFormat(addon.price) }} / Monat
                </div>
            </div>
            <span class="inline-flex text-base font-semibold tracking-wider text-gray-600"></span>
            <Switch :modelValue="checkIsAddonActive(addon.id)"
                    @update:modelValue="onAddonToggle(addon.id, $event)"></Switch>
        </div>
        <div class="bg-white flex-col space-y-4 rounded-t-lg px-6 pt-6 pb-10">
            <span>{{ addon.description }}</span>
            <Checklist v-if="addon.checklist" :items="addon.checklist" class="space-y-4"/>
            <div class="flex-col" v-if="addon.linkcollection.length > 0">
                <span class="text-gray-600 text-base">Weitere informationen: </span>
                <div class="flex">
                    <a v-for="(link, index) in addon.linkcollection" :key="index" class="text-gray-500 pr-2 transition-colors ease-in-out duration-200 hover:text-red-600 text-base" target="_blank" :href="link.href">{{link.text}}</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import {moneyFormat} from "../domain/utils";
import Switch from "./Switch";
import Checklist from "../Checklist";
import {useCheckoutStore} from "../stores/checkout";
import {computed, ref} from "vue";

const checkoutStore = useCheckoutStore();

const props = defineProps({
  modelValue: {
    type: Array,
    default: []
  },
  options: {
    type: Object,
    default: {
      additionalProductAddons: false
    }
  }
})

let productAddons = ref(props.options.additionalProductAddons ? checkoutStore.additionalProductAddons : checkoutStore.productAddons);

const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

const onAddonToggle = (addonId, isChecked) => {
    if(isChecked) {
        const excludedAddons = productAddons.value?.find(a => a.id === addonId)?.addonExclusion;
        excludedAddons.forEach(excludedAddon => {
            if(checkIsAddonActive(excludedAddon)) {
                setTimeout(() => {
                    onAddonToggle(excludedAddon, false)
                })
            }
        })
    }
    value.value = isChecked ? [...value.value, addonId] : value.value.filter(id => id !== addonId)
}

const checkIsAddonActive = (addonId) => {
    return value.value.includes(addonId)
}
</script>
