import {reactive, ref} from "vue";
import {steps} from "./schema";
import {set} from 'lodash';

import {useCheckoutStore} from "../stores/checkout";
import {useFormStore} from "../stores/form";
import {storeToRefs} from "pinia";

let product = null
let consumption = null
let formValues = null
let formStore = null

export const loadStore = () => {
    const checkoutStore = useCheckoutStore();
   const checkoutValues = storeToRefs(checkoutStore)
    formStore = useFormStore()();
    product = checkoutValues['product'];
    consumption = checkoutValues['consumption']
    formValues = ref(formStore.$state);
}

export {steps};

export const dirtyFields = reactive(new Set());
export const visitedSteps = reactive(new Set());

export const getAllFields = () => {
    return steps.map(step => step.groups).flat().map(group => group.fields).flat();
}

export const activeStepSlug = ref(steps[0].meta.slug)

export function getStep(stepId) {
    return steps.find(step => step.meta.id === stepId)
}

export function getStepBySlug(slug) {
    return steps.find(step => step.meta.slug === slug)
}
const getAllTariffOptions = (foundProducts) => {
    return foundProducts.map((product)=>{
        return  {
            item_id: product.id,
            item_name: product.name,
            item_category: product.section === 'electricity'|| 'carpower' ? 'Strom' : 'Gas',
            item_category2: "Tarif",
            price : product.price.monthlyGross,
        }
    })
}
export async function  addAnalyticsEventBeforeCheckout  ( eventName, index) {
    const checkoutStore = useCheckoutStore();
    await checkoutStore.fetchPriceData();
    const checkoutValues = storeToRefs(checkoutStore)
    const priceData = checkoutValues['priceDataResponse'];
    const foundProducts =  Object.values(priceData.value ?? [])?.sort((a, b) => a.order - b.order).sort((a, b) => a.bonusWorld - b.bonusWorld);
    if (window.hasOwnProperty("gtag") && typeof gtag === "function" ) {
        gtag('get',window.analyticsId, 'session_id', (sessionId) => {
            if(sessionId){
                gtag("event", eventName, {
                    ga_session_id: sessionId,
                    value: eventName === 'add_to_cart' ?  (foundProducts[index].price.monthlyGross * 12)  : 0,
                    currency: "EUR",
                    payment_method: "",
                    items: eventName === 'add_to_cart' ? [
                        {
                            item_id: foundProducts[index].id,
                            item_name: foundProducts[index].name,
                            item_category: foundProducts[index].section === 'electricity'||foundProducts[index].section ===  'carpower' ? 'Strom' : 'Gas',
                            item_category2: "Tarif",
                            price :foundProducts[index].price.monthlyGross,
                        }] : getAllTariffOptions(foundProducts)
                });
            }
        })

    }
}
export function addGoogleAnalyticsEventCheckout(eventName, paymentMethod =''){
    if (window.hasOwnProperty("gtag") && typeof gtag === "function" ) {
        const checkoutStore = useCheckoutStore();
        const checkoutValues = storeToRefs(checkoutStore)
        const priceData = checkoutValues['priceDataResponse'];
        const product = checkoutValues['product'];
        gtag('get',window.analyticsId, 'session_id', (sessionId) => {
            if(sessionId){
                gtag("event", eventName, {
                    ga_session_id: sessionId,
                    value: eventName !== 'purchase' ? (priceData.value?.monthlyGross *12): getYearlyPriceOfAllItemsInCart(checkoutValues),
                    currency: "EUR",
                    payment_method: paymentMethod,
                    items: eventName !== 'purchase' ?[
                        {
                            item_id: product.value?.id,
                            item_name: product.value?.name,
                            item_category: product.value?.section === 'electricity' || product.value?.section === 'carpower' ? 'Strom' : 'Gas',
                            item_category2: "Tarif",
                            price : priceData.value?.monthlyGross,
                        }] : getAllItemsInCart(checkoutValues)
                });
            }
        })


    }
}

function getYearlyPriceOfAllItemsInCart(checkoutValues){
    const monthlyPrice = checkoutValues['priceDataResponse'];
    const monthlyAdditionalProductPrice = checkoutValues['monthlyAdditionalProductPrice'];
    if(monthlyAdditionalProductPrice.value) {
        return (monthlyAdditionalProductPrice.value * 12 + monthlyPrice.value.monthlyGross * 12)
    }
    return (monthlyPrice.value.monthlyGross * 12)
}

function getAllItemsInCart(checkoutValues){
    const submitValues = prepareSubmitValues();
    const items = [];
    const product = checkoutValues['product'];
    const monthlyPrice = checkoutValues['priceDataResponse'];
    const additionalProduct = checkoutValues['additionalProduct'];
    const monthlyAdditionalProductPrice = checkoutValues['monthlyAdditionalProductPrice'];
    if(submitValues.product_id){
        items.push({
            item_id: submitValues.product_id,
            item_name: product.value?.name,
            item_category: product.value?.section === 'electricity' ||product.value?.section === 'carpower' ? 'Strom' : 'Gas',
            item_category2: "Tarif",
            price : monthlyPrice.value?.monthlyGross,
        })
    }
    if(submitValues.additionalProduct){
        items.push({
            item_id: submitValues.additionalProduct,
            item_name: additionalProduct.value?.name,
            item_category: additionalProduct.value?.section === 'electricity'|| additionalProduct.value?.section === 'carpower' ? 'Strom' : 'Gas',
            item_category2: "Zusatzprodukt",
            price : monthlyAdditionalProductPrice.value,
        })
    }
    if(submitValues.addonProducts?.length>0){
        submitValues.addonProducts.forEach((addonProduct) => {
            const selectedAddons = checkoutValues['selectedAddons'];
            const currentAddon = selectedAddons.value.find((addon) => addon.id === addonProduct);
            items.push({
                item_id: addonProduct,
                item_name: currentAddon.name,
                item_category: '',
                item_category2: "Addon",
                price : currentAddon.price,
            })
        })
    }
    if(submitValues.additionalProductAddonProduct?.length>0){
        submitValues.additionalProductAddonProduct.forEach((addonProduct) => {
            const selectedAddons = checkoutValues['selectedAdditionalProductAddons'];
            const currentAddon = selectedAddons.value.find((addon) => addon.id === addonProduct);
            items.push({
                item_id: addonProduct,
                item_name: currentAddon.name,
                item_category: '',
                item_category2: "Addon",
                price: currentAddon.price,
            })
        })
    }
    return items
}

export function getPreviousStep(stepId) {
    if (!stepId) {
        return null;
    }

    const actualIndex = steps.findIndex(step => step.meta.id === stepId);

    if (actualIndex === 0) {
        return null;
    }

    for (let i = steps.length - 1; i >= 0; i--) {
        if (i < actualIndex && !steps[i].meta.hideInStepper) {
            return steps[i]
        }
    }

    return null;
}

export function getNextStep(stepId) {
    if (!stepId) {
        return null;
    }

    const actualIndex = steps.findIndex(step => step.meta.id === stepId);
    if (actualIndex === steps.length - 1) {
        return null;
    }

    const nextIndex = steps.findIndex((step, index) =>
        index > actualIndex && !step.meta.hideInStepper
    );

    if (!nextIndex) {
        return null;
    }

    return steps[nextIndex];
}

export function isStepEnabled(stepId, values) {
    const previousStep = getPreviousStep(stepId);
    return previousStep ? isStepComplete(previousStep.meta.id, values) : true;
}


export function isStepVisited(stepId) {
    return visitedSteps.has(stepId);
}

export function isStepComplete(stepId, values) {
    if (!isStepEnabled(stepId, values)) {
        return false;
    }

    if(!isStepVisited(stepId)) {
        return false;
    }
    const step = getStep(stepId);
    return step.groups.map(g => g.fields).flat().every(field => {
        return !areConditionsFulfilled(field, values) || checkIsFieldValid(field, values, true)
    })
}

export const getDirtyFields = (formValues) => {
    return Object.keys(formValues)
}

export const areConditionsFulfilled = (field, values) => {
    if (!field.conditions) {
        return true;
    }

    const conditions = typeof field.conditions === 'function' ? [field.conditions] : field.conditions;
    return conditions.every((conditionFunction) => {
        return conditionFunction(values)
    })
}

export const processField = (fieldValue, values) => {
    if (typeof fieldValue === 'function') {
        return fieldValue(values);
    }

    return fieldValue;
}

export const getFieldError = (field, values, ignoreDirty = false, dirtyFields = new Set()) => {
    if (!field.validation || (!ignoreDirty && !dirtyFields.has(field.name))) {
        return null;
    }
    try {
        field.validation.validateSync(values[field.name], {context: values})
    } catch (e) {
        if(e.errors){
            return e?.errors[0];
        }
    }
}

export const checkIsFieldValid = (field, values, ignoreDirty = false, dirtyFields = new Set()) => {

    if (!ignoreDirty && !dirtyFields.has(field.name)) {
        return false;
    }
    return !getFieldError(field, values, ignoreDirty, dirtyFields);
}

export const getSubmitKeys = (field, values) => {
    let keys = field.submitKey;
    if(!keys) {
        return [];
    }
    if(!Array.isArray(keys)) {
        keys = [keys];
    }
    return keys.map((key) => {
        return typeof key === "function" ? key(values) : key;
    })
}

export const prepareSubmitValues = () => {
    const formStore = useFormStore("checkout")();
    const fields = getAllFields();
    const submitValues = {};
    fields.forEach(field => {
        if(areConditionsFulfilled(field, formStore.$state)) {
            if(field.hideInSubmit === true) {
                return true;
            }
            getSubmitKeys(field, formStore.$state).map(key => {
                const value = formStore.$state[field.name];
                const submitTransform = field.submitTransform || ((v) => v)
                set(submitValues, key, submitTransform(value));
            });
        }
    });
    submitValues['product_id'] = product.value.id;
    submitValues['usage'] = consumption.value;
    submitValues['billing_type'] = 'billingTypes.online.active';
    return submitValues;
}

export const isSubmitting = ref(false);

export const submit = async () => {
    isSubmitting.value = true;
    const submitValues = prepareSubmitValues();
    addGoogleAnalyticsEventCheckout('purchase', submitValues.payment_data.type)
    try {
        const response = await fetch('/api/orders', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(submitValues)
        }).then(response => response.json())
        submitCallbackHandler(response);
    }
    finally {
        isSubmitting.value = false;
    }
}

const submitCallbackHandler = (response) => {
    if(Object.keys(response).includes('redirectUrl')){
        window.location = response.redirectUrl;
    }
}
