import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import {map} from "lodash";
import Summary from "../Summary";
import Addons from "../fields/Addons";
import Select from "../fields/Select";
import Switch from "../fields/Switch";
import ConsumptionPicker from "../ConsumptionPicker";
import Combobox from "../fields/Combobox";
import Input from "../Input";
import Address from "../fields/Address";
import AdditionalProducts from "../fields/AdditionalProducts";
import Iban from "../Iban";
import DatePicker from "../fields/DatePicker";
import YesNo from "../summary/YesNo";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import AddonsSummary from "../summary/Addons";
import Documents from "../summary/Documents";
import MetaText from "../MetaText";
import AdditionalProductSummary from "../summary/AdditionalProductSummary";
import {useCheckoutStore} from "../stores/checkout";
import {storeToRefs} from "pinia";
let product = null
let asset = []

export const loadStore = () => {
    const checkoutStore = useCheckoutStore();

    product = storeToRefs(checkoutStore)['product'];
    asset = storeToRefs(checkoutStore)['assets'];
}
dayjs.extend(customParseFormat);
const today = dayjs().endOf('date');

const mindate = new Date();
mindate.setDate(mindate.getDate() - 42)
const maxdate = new Date();
maxdate.setDate(maxdate.getDate() + 14);
const minsupplier = new Date();
minsupplier.setDate(minsupplier.getDate() + 14);
const maxsupplier = new Date();
maxsupplier.setDate(maxsupplier.getDate() + 365);
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

Yup.addMethod(Yup.date, 'format', function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date('');
    });
});


const dayTransform = (value) => dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');

const preparedData = await fetch('/api/settings').then(response => response.json());
console.log(preparedData);

export const steps = [
    {
        meta: {
            id: "1",
            name: "Adresse",
            description: "und persönliche Angaben",
            slug: "adresse"
        },
        groups: [
            {
                title: "Bestellung",
                description: "Bitte geben Sie uns noch ein paar Informationen zu Ihrer Bestellung",
                fields: [
                    {
                        type: Select,
                        options: [
                            {value: 'moving', name: 'Neueinzug'},
                            {value: 'product-change', name: 'Produktwechsel'},
                            {value: 'supplier-change', name: 'Versorgerwechsel'}

                        ],
                        placeholder: "Bitte wählen Sie aus",
                        label: "Bestellungstyp",
                        name: "orderType",
                        submitKey: "order_type",
                        summary: SelectSummary,
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                            .oneOf(['moving', 'product-change', 'supplier-change'], 'Ungültiger Wert')
                    },
                    {
                        type: Combobox,
                        options: () => {
                            return map(preparedData['commodity.' + product?.value?.section + '.suppliers'], (supplier)  => {
                                return {value: supplier.id, name: supplier.name}
                            }).filter(supplier => supplier.code !== '0');
                        },
                        label: 'Bisheriger Versorger',
                        name: 'previousProvider',
                        submitKey: 'order_data.supplier',
                        summary: SelectSummary,
                        conditions: (values) => values.orderType === 'supplier-change',
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Input,
                        label: 'Kundennummer beim bisherigen Versorger',
                        name: 'previousCustomerNumber',
                        submitKey: 'order_data.customerNumber',
                        conditions: (values) => values.orderType === 'supplier-change',
                        validation: Yup.string().default('')
                    },
                    {
                        type: Switch,
                        name: 'hasSelfCancelled',
                        label: 'Schon gekündigt',
                        description: 'Ich habe meinen Vertrag mit dem bisherigen Versorger schon gekündigt',
                        conditions: (values) => values.orderType === 'supplier-change',
                        submitKey: 'order_data.hasSelfCancelled',
                        summary: YesNo,
                        validation: Yup.boolean()
                            .default(false)
                    },
                    {
                        type: Input,
                        label: 'Bisherige Vertragskontonummer',
                        submitKey: 'order_data.customerNumber',
                        name: 'previousContractNumber',
                        conditions: (values) => values.orderType === 'product-change',
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                            .matches(/^[0-9]+$/, "Vertragsnummer muss nur aus Ziffern bestehen")
                            .min(10, 'Vertragsnummer muss zehnstellig sein')
                            .max(10, 'Vertragsnummer muss zehnstellig sein')
                    },
                    {
                        type: DatePicker,
                        label: 'Einzugsdatum',
                        name: 'moveInDate',
                        submitKey: 'order_data.desiredDeliveryDate',
                        submitTransform: dayTransform,
                        conditions: (values) => values.orderType === 'moving',
                        minDate: (values) => preparedData.change_dates[values.orderType]?.earliest,
                        maxDate: (values) => preparedData.change_dates[values.orderType]?.latest,
                        validation: Yup.date()
                            .format('DD.MM.YYYY', true)
                            .default('')
                            .typeError('Ungültiges Datumsformat')
                            .required('Bitte ausfüllen'),
                        placeholder: 'tt.mm.jjjj',
                    },
                    {
                        type: DatePicker,
                        label: 'Kündigungsdatum',
                        name: 'selfCancellationDate',
                        submitKey: 'order_data.selfCancellationDate',
                        submitTransform: dayTransform,
                        conditions: (values) => values.hasSelfCancelled === true,
                        validation: Yup.date()
                            .format('DD.MM.YYYY', true)
                            .default('')
                            .typeError('Ungültiges Datumsformat')
                            .required('Bitte ausfüllen'),
                        placeholder: 'tt.mm.jjjj'
                    },
                    {
                        type: Switch,
                        name: 'isNextPossibleChangeDate',
                        label: 'Auftrag schnellstmöglich Schalten',
                        description: 'Der Auftrag wird zum nächstmöglichen Termin geschaltet',
                        conditions: (values) => values.orderType && values.orderType !== "moving",
                        submitKey: 'order_data.isNextPossibleChangeDate',
                        summary: YesNo,
                        validation: Yup.boolean()
                            .default(false)
                    },
                    {
                        type: Switch,
                        hidden: true,
                        name: 'isNextPossibleChangeDate',
                        label: 'Auftrag schnellstmöglich Schalten',
                        description: 'Der Auftrag wird zum nächstmöglichen Termin geschaltet',
                        conditions: (values) => values.orderType && values.orderType === "moving",
                        submitKey: 'order_data.isNextPossibleChangeDate',
                        hideInSummary: true,
                        default: true
                    },
                    {
                        type: DatePicker,
                        label: 'Gewünschter Auftragsbeginn',
                        name: 'desiredDeliveryDate',
                        minDate: (values) => {
                            const earliest = dayjs(preparedData.change_dates[values.orderType]?.earliest, 'YYYY-MM-DD').toDate();

                            if(values.hasSelfCancelled === true && values.selfCancellationDate) {
                                const selfCancellationDate = dayjs(values.selfCancellationDate, 'DD.MM.YYYY').toDate();
                                return dayjs(selfCancellationDate).isBefore(earliest) ? earliest : selfCancellationDate;
                            }

                            return earliest;
                        },
                        maxDate: (values) => preparedData['change_dates'][values.orderType]?.latest,
                        submitKey: 'order_data.desiredDeliveryDate',
                        submitTransform: dayTransform,
                        conditions: (values) => (values.orderType === 'product-change' || values.orderType === 'supplier-change') && (values.isNextPossibleChangeDate === undefined ||  values.isNextPossibleChangeDate === false),
                        validation: Yup.date()
                            .format('DD.MM.YYYY', true)
                            .default('')
                            .typeError('Ungültiges Datumsformat')
                            .required('Bitte ausfüllen'),
                        placeholder: 'tt.mm.jjjj'
                    }
                ]
            },
            {
                title: "Persönlichen Angaben",
                description: "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
                fields: [
                    {
                        type: Select,
                        options: [
                            {value: 'salutations.mrs.active', name: 'Frau'},
                            {value: 'salutations.mr.active', name: 'Herr'},
                            {value: 'salutations.other.active', name: 'Divers'},
                            {value: 'salutations.married.active', name: 'Eheleute'},
                        ],
                        placeholder: "Bitte wählen Sie aus",
                        label: "Anrede",
                        name: "salutation",
                        submitKey: "customer_data.salutation",
                        summary: SelectSummary,
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                            .oneOf(['salutations.mrs.active', 'salutations.other.active', 'salutations.mr.active', 'salutations.married.active'], 'Ungültiger Wert')
                    },
                    {
                        type: Input,
                        label: 'Vorname',
                        name: 'firstname',
                        size: 'half',
                        submitKey: 'customer_data.firstName',
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Input,
                        label: 'Nachname',
                        name: 'lastname',
                        size: 'half',
                        submitKey: 'customer_data.lastName',
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Input,
                        label: 'Geburtstag',
                        name: 'birthday',
                        mask: {
                            mask: Date,
                            min: new Date(1900, 0, 1),
                            max: new Date(today),
                            lazy: false
                        },
                        submitKey: 'customer_data.birthday',
                        submitTransform: dayTransform,
                        validation: Yup.date()
                            .format('DD.MM.YYYY', true)
                            .default('')
                            .typeError('Ungültiges Datumsformat')
                            .test(
                                'legal-age',
                                (d) => `Sie müssen mindestens 18 Jahre alt sein`,
                                (value) => {
                                const diff = Date.now() - value.getTime();
                                const ageDate = new Date(diff);
                                let age = Math.abs(ageDate.getUTCFullYear() - 1970);
                                return age > 18;
                                }
                            )
                            .required('Bitte ausfüllen'),
                    },
                    {
                        type: Input,
                        subtype: 'email',
                        label: 'E-Mail',
                        name: 'email',
                        submitKey: 'customer_data.email',
                        validation: Yup.string()
                            .default('')
                            .email('Dies ist keine gültige E-Mail Adresse')
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Input,
                        subtype: 'email',
                        label: 'E-Mail Bestätigung',
                        name: 'emailConfirmation',
                        hideInSubmit: true,
                        validation: Yup.string()
                            .default('')
                            .email('Dies ist keine gültige E-Mail Adresse')
                            .required('Bitte ausfüllen')
                            .test(
                                'isSameEmail',
                                (d) => `Die E-Mail Adressen stimmen nicht überein`,
                                (value, {options}) => options.context.email === value
                            ),
                    },
                    {
                        type: Input,
                        label: 'Telefonnummer',
                        name: 'phone',
                        mask: {
                            mask: '+{49} (\\0) 000 0000000000'
                        },
                        submitKey: 'customer_data.phone',
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                    },
                ]
            },
            {
                title: "Zähler",
                description: "Die Zählerinformationen helfen uns, Ihren Anschluss schnell zuzuordnen und Ihnen möglichst schnell Ihren Wunschtarif freizuschalten",
                fields: [
                    {
                        type: DatePicker,
                        label: 'Tag der Ablesung',
                        name: 'dayOfReadingActualUsage',
                        maxDate: new Date(),
                        submitKey: 'order_data.dayOfReadingActualUsage',
                        submitTransform: dayTransform,
                        validation: Yup.date()
                            .format('DD.MM.YYYY', true)
                            .default(today)
                            .required("Bitte ausfüllen")
                            .typeError('Ungültiges Datumsformat'),
                        placeholder: 'tt.mm.jjjj'
                    },
                    {
                        type: Input,
                        label: 'Zählernummer',
                        name: 'meterNumber',
                        submitKey: 'order_data.meterNumber',
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Input,
                        label: 'Zählerstand',
                        name: 'meterReading',
                        submitKey: 'order_data.actualMeterUsage',
                        validation: Yup.number()
                            .typeError("Der Zählerstand darf nur aus Zahlen bestehen")
                            .positive("Der Zählerstand darf nur positiv sein")
                            .integer("Bitte den Zählerstand ohne Kommastellen eingeben")
                            .required("Bitte ausfüllen")
                    },
                ]
            },
            {
                title: "Adresse",
                fields: [
                    {
                        type: Address,
                        name: 'shippingAddress',
                        submitKey: 'shipping_data',
                        summary: AddressSummary,
                        label: 'Lieferadresse',
                        options: {
                            required: true
                        },
                        validation: Yup.object().test(
                            "addressRequired",
                            ({value}) => {
                                return {
                                    city: !value?.city.length,
                                    postcode: !value?.postcode.length,
                                    number: !value?.number.length,
                                    street: !value?.street.length,
                                    message: "Bitte ausfüllen"
                                }
                            },
                            (value) => {
                                return value?.city.length > 0 && value?.postcode.length > 0 && value?.number.length > 0 && value?.street.length > 0;
                            }
                        ).nullable()
                    },
                    {
                        type: Switch,
                        label: 'Abweichende Rechnungsadresse',
                        description: 'Unterscheiden sich Liefer- und Rechnungsadresse?',
                        name: 'differentBillingAddress',
                        summary: YesNo,
                        validation: Yup.boolean()
                    },
                    {
                        type: Address,
                        label: 'Rechnungsadresse',
                        name: 'billingAddress',
                        submitKey: 'billing_data',
                        summary: AddressSummary,
                        conditions: (values) => values.differentBillingAddress,
                        labels: {
                            postcode: 'Postleitzahl'
                        }
                    }
                ]
            }
        ]
    },
    {
        meta: {
            id: "2",
            name: "Zahlungsart",
            description: "Wie möchten Sie zahlen?",
            slug: "zahlungsart"
        },
        groups: [
            {
                title: 'Zahlungsart',
                description: "Wie möchten Sie bezahlen?",
                fields: [
                    {
                        type: Select,
                        options: [
                            {value: 'sepa', name: 'Lastschrift'},
                            {value: 'transfer', name: 'Überweisung'}
                        ],
                        placeholder: "Bitte wählen Sie aus",
                        label: "Zahlungsmethode",
                        name: "paymentType",
                        submitKey: "payment_data.type",
                        summary: SelectSummary,
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                            .oneOf(['sepa', 'transfer'], 'Ungültiger Wert')
                    },
                    {
                        type: Input,
                        label: 'Kontoinhaber',
                        name: 'accountHolder',
                        submitKey: 'payment_data.accountHolder',
                        conditions: (values) => values.paymentType === 'sepa',
                        validation: Yup.string()
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Iban,
                        label: 'IBAN',
                        name: 'iban',
                        mask: {
                            mask: 'aa00 0000 0000 0000 0000 0000 0000 0000 00'
                        },
                        submitKey: 'payment_data.iban',
                        placeholder: "DEXX XXXX XXXX XXXX XXXX XX",
                        conditions: (values) => values.paymentType === 'sepa',
                        validation: Yup.string()
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Input,
                        label: 'BIC',
                        name: 'bic',
                        submitKey: 'payment_data.bic',
                        placeholder: "XXXX XX XX XXX ",
                        conditions: (values) => values.paymentType === 'sepa',
                        validation: Yup.string()
                            .default('')
                    },
                ]
            }
        ]
    },
    {
        meta: {
            id: "3",
            name: "Addons",
            description: "Zusatzpakete hinzubuchen",
            slug: "addons"
        },
        groups: [
            {
                title: 'Addons',
                description: 'Nützliche Erweiterungen für Ihren Tarif',
                card: false,
                fields: [
                    {
                        type: Addons,
                        name: 'addons',
                        label: 'Ausgewählte Addons',
                        submitKey: "addonProducts",
                        summary: AddonsSummary
                    }
                ]
            },
        ]
    },
    {
        meta: {
            id: "4",
            name: "Dokumente",
            slug: "documents",
            hideInStepper: true
        },
        groups: [
            {
                title: 'Dokumente',
                card: false,
                fields: [
                    {
                        options: [],
                        summary: Documents
                    }
                ]
            },
        ]
    },
    {
        meta: {
            id: "5",
            name: "Zusatzprodukte",
            description: "Weitere Produkte auswählen",
            slug: "zusatz"
        },
        groups: [
            {
                title: 'Zusatzprodukt konfigurieren',
                description: '',
                card: true,
                fields: [
                    {
                        type: AdditionalProducts,
                        name: 'additionalProduct',
                        submitKey: "additionalProduct",
                        label: "Ausgewählte Zusatzprodukte",
                        hideInSubmit: false,
                        summary: AdditionalProductSummary
                    },
                    {
                        type: ConsumptionPicker,
                        label: 'Ihr Verbrauch',
                        name: 'additionalProductConsumption',
                        submitKey: "additionalProductConsumption",
                        default: 1000,
                        resolveSubtype: true,
                        conditions: (values) => !!values.additionalProduct,
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Input,
                        label: 'Zählernummer',
                        submitKey: "additionalProductMeterNumber",
                        name: 'additionalProductMeterNumber',
                        conditions: (values) => !!values.additionalProduct,
                        validation: Yup.string()
                            .default('')
                            .required('Bitte ausfüllen')
                    },
                    {
                        type: Addons,
                        name: 'additionalProductAddonProduct',
                        label: 'Ausgewählte Addons',
                        options: {
                          additionalProductAddons: true
                        },
                        conditions: (values) => !!values.additionalProduct,
                        submitKey: "additionalProductAddonProduct",
                        summary: AddonsSummary
                    },
                    {
                        label: 'Dokumente',
                        options: [
                            {value: true, name: 'additionalProduct'}
                        ],
                        conditions: (values) => !!values.additionalProduct,
                        summary: Documents
                    }
                ]
            }
        ]
    },
    {
        meta: {
            id: "6",
            name: "Abschließen",
            slug: "abschluss"
        },
        groups: [
            {
                title: 'Zusammenfassung',
                description: 'Überprüfen Sie Ihre Daten',
                card: false,
                hideInSummary: true,
                fields: [
                    {
                        type: Summary,
                        name: 'summary',
                        hideInSubmit: true,
                    }
                ]
            },
            {
                title: 'Datenschutz',
                hideInSummary: true,
                fields: [
                    {
                        type: MetaText,
                        name: "customerDisclaimer",
                        label: "Datenschutzbestimmungen",
                        description:
                            "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                            "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                    },
                    {
                        type: MetaText,
                        name: 'customerConstract',
                        label: 'Allgemeine Geschäftsbedingungen',
                        description: "Unsere Allgemeinen Geschäftsbedingungen finden Sie " + "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>hier</a>",
                    },
                    {
                        type: Switch,
                        name: 'customerDisclaimer',
                        label: 'Widerrufsbelehrung',
                        submitKey: ['privacy_data.isCustomerDisclaimerAccept'],
                        description: "Ich habe die Widerrufsbelehrung zur Kenntnis genommen und akzeptiere diese. Meinen schriftlichen Widerruf kann ich über das "  + "<a href=\'/PDFs/Bestellung/Widerrufsformular.pdf\' target=\'_blank\'>Widerrufsformular</a>. " + "an die Stadtwerke Herne senden.",
                        validation: Yup.boolean()
                            .oneOf([true], 'Bitte akzeptieren Sie die Widerrufsbelehrung')
                            .required('Bitte akzeptieren Sie die Widerrufsbelehrung')
                    },
                    {
                        type: Switch,
                        name: 'advertisingTerm',
                        label: 'Werbeeinwilligung',
                        submitKey: 'privacy_data.isAdvertisingTermsAccept',
                        description: 'Ja, ich bin damit einverstanden, dass die Stadtwerke Herne AG mich über ihre Produkte der Strom- und Erdgasversorgung sowie Energiedienstleistungen informiert. Dazu darf die Stadtwerke Herne AG mich per kontaktieren:',
                    },
                    {
                        type: Switch,
                        name: 'advertisingPhone',
                        label: 'Telefon',
                        conditions: (values) => values.advertisingTerm === true,
                        submitKey: 'privacy_data.isAdvertisingPhoneAccept',
                        description: '(bitte Zutreffendes ankreuzen)',
                    },
                    {
                        type: Switch,
                        name: 'advertisingMail',
                        label: 'E-Mail',
                        conditions: (values) => values.advertisingTerm === true,
                        submitKey: 'privacy_data.isAdvertisingMailAccept',
                        description: '(bitte Zutreffendes ankreuzen)',
                    },
                    {
                        type: Input,
                        subtype: "email",
                        label: "E-Mail",
                        name: "advertisingEmail",
                        submitKey: "customer_data.advertisingEmail",
                        conditions: (values) => values.advertisingMail === true,
                        validation: Yup.string()
                            .default("")
                            .email("Dies ist keine gültige E-Mail Adresse")
                            .required("Bitte ausfüllen"),
                    },
                    {
                        type: MetaText,
                        title: "Einwilligung",
                        description: "Diese Einwilligung kann ich jederzeit widerrufen, z. B. per Brief an die Stadtwerke Herne AG, Grenzweg 18, 44623 Herne oder per E-Mail kundenservice@stadtwerke-herne.de" +
                            " Informationen dazu, wie wir Ihre personenbezogenen Daten verarbeiten, finden Sie in der " + "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                    }
                ]
            },
        ]
    }
]
