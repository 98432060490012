<template>
    <component
        :is="componentType"
        :disabled="computedDisabled"
        class="z-auto inline-flex relative whitespace-nowrap py-3 px-6 text-xl tracking-widest leading-7 text-center rounded-lg border-2 border-solid cursor-pointer md:my-5 box-border"
        style="transition: background-color 0.3s ease 0s, color 0.3s ease 0s;"
        :class="classes"
        :href="!computedDisabled ? href : ''"
    >
        <div :class="loading && 'opacity-0'">
            <slot></slot>
        </div>
        <div v-if="loading" class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <LoadingSpinner size="sm" color="white"></LoadingSpinner>
        </div>
    </component>

</template>

<script>
export default {
    name: "stwh-button"
}
</script>
<script setup>

import {computed, defineComponent} from "vue";
import LoadingSpinner from "./LoadingSpinner";


const props = defineProps({
    disabled: Boolean,
    href: String,
    inset: Boolean,
    glow: Boolean,
    loading: Boolean,
    color: {
        type: String,
        default: 'red'
    }
})

const componentType = computed(() => props.href ? 'a' : 'button')

const colorClasses = computed(() => ({
    red: {
        default: ['bg-red-600 border-red-600 hover:bg-red-400 text-white', props.glow && 'shadow-red-600/30 shadow-lg'],
        inset: 'text-red-600 border-red-600 hover:bg-red-600 hover:text-white'
    },
    gray: {
        default: ['bg-gray-600 border-gray-600 hover:bg-gray-400 text-white', props.glow && 'shadow-gray-600/30 shadow-lg'],
        inset: 'text-gray-600 border-gray-600 hover:bg-gray-600 hover:text-white'
    }
}))

const computedDisabled = computed(() => props.disabled || props.loading )

const classes = computed(() => {
    return [
        computedDisabled.value ? 'cursor-not-allowed' : 'cursor-pointer',
        props.inset && !computedDisabled.value && colorClasses.value[props.color].inset,
        props.inset && computedDisabled.value && `text-gray-500 bg-gray-100 border-gray-500`,
        !props.inset && !computedDisabled.value && colorClasses.value[props.color].default,
        !props.inset && computedDisabled.value && `bg-gray-300 border-gray-300 text-white`,
    ]
})


</script>
