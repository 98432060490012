import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import SelectSummary from "../summary/Select";
import Switch from "../fields/Switch";
import MetaText from "../MetaText";
import Text from "../Text.vue";

dayjs.extend(customParseFormat);

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        console.log("react", value)
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

export default {
    groups: [
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Input,
                    label: "Vertragskontonummer",
                    name: "contractnumber",
                    submitKey: "customer_data.contractnumber",
                    placeholder: "Siehe Rechnung",
                    validation: Yup.string()
                        .default("")
                        .matches(/^$|[0-9]{10}$/, "Vertragsnummer muss aus 10 Ziffern bestehen"),
                },
                {
                    type: Select,
                    options: [
                        {value: "female", name: "Frau"},
                        {value: "male", name: "Herr"},
                        {value: "other", name: "Divers"},
                        {value: "spouses", name: "Eheleute"},
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["female", "male", "other", "spouses"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Titel",
                    name: "title",
                    submitKey: "customer_data.title",
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "customer_data.phone",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    submitKey: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, {options}) =>
                                options.context.email === value
                        ),
                },
                {
                    type: Text,
                    name: "comments",
                    submitKey: "customerMessage",
                    label: "Ihre Nachricht",
                }
            ],
        },
        {
            title: "Newsletter",
            hideInSummary: true,
            fields: [
                {
                    type: Switch,
                    name: 'advertisingTerm',
                    label: 'Werbeeinwilligung',
                    submitKey: 'isAdvertisingTermsAccepted',
                    description: 'Ja, ich bin damit einverstanden, dass die Stadtwerke Herne AG mich über ihre Produkte der Strom- und Erdgasversorgung sowie Energiedienstleistungen informiert. Dazu darf die Stadtwerke Herne AG mich per Mail kontaktieren:',
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "advertisingEmail",
                    submitKey: "advertisingEmail",
                    conditions: (values) => values.advertisingTerm === true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: MetaText,
                    title: "Einwilligung",
                    description: "Diese Einwilligung kann ich jederzeit widerrufen, z. B. per Brief an die Stadtwerke Herne AG, Grenzweg 18, 44623 Herne oder per E-Mail kundenservice@stadtwerke-herne.de" +
                        " Informationen dazu, wie wir Ihre personenbezogenen Daten verarbeiten, finden Sie in der " + "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                }
            ]
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
            ],
        },
    ],
    submitUrl: "/api/form",
};
