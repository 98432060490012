<template>
    <span v-if="isAdditionalProduct && additionalProduct && additionalProduct.assets">
        <div class="sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
            <div v-for="asset in additionalProduct.assets" class="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a :href="asset.url" target="_blank">{{ asset.name }}</a>
            </div>
        </div>
    </span>
    <span v-if="!isAdditionalProduct && product && product.assets">
        <div class="sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
            <div v-for="asset in product.assets" class="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a :href="asset.url" target="_blank">{{ asset.name }}</a>
            </div>
        </div>
    </span>
</template>

<script setup>
import {useCheckoutStore} from "../stores/checkout";
import {storeToRefs} from "pinia";
import { onMounted, computed } from 'vue'

const props = defineProps({
    options: {
        required: false,
        type: Array
    },
});

const checkoutStore = useCheckoutStore();
let {additionalProduct, product} = storeToRefs(checkoutStore)

const isAdditionalProduct = computed(() => {
    return props.options?.find((o) => o.name==='additionalProduct');
})
</script>
