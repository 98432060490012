import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Address from "../fields/Address";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import MetaText from "../MetaText";
import Switch from "../fields/Switch";
import YesNo from "../summary/YesNo";


dayjs.extend(customParseFormat);
let today = dayjs().endOf('date');

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

Yup.addMethod(Yup.date, "legalAge", function (errorMessage) {
    return this.test("test-legal-age", errorMessage, (value) => {
        let birthday = Date.parse(value);
        const diff = Date.now() - birthday.getTime();
        const ageDate = new Date(diff);
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age < 18;
    })
})

const dayTransform = (value) => dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD");
const hernePostcodes = [44623, 44628, 44629,44649, 44652, 44653, 44627, 44628, 44649, 44653, 44651, 44652, 44627, 44623, 44625, 44627, 44628, 44629, 44623, 44625, 	44625, 44652, 44627, 44628, 44628, 44629, 	44649, 44651, 44625, 44627, 44628, 44653, 	44649, 44652, 44653, 44651, 44652, 44653]
export default {
    groups: [
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Select,
                    options: [
                        { value: "female", name: "Frau" },
                        { value: "male", name: "Herr" },
                        { value: "other", name: "Divers" },
                        { value: "spouses", name: "Eheleute" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["female", "male", "other", "spouses"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Titel",
                    name: "title",
                    submitKey: "customer_data.title",
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    submitKey: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, { options }) =>
                                options.context.email === value
                        ),
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "customer_data.phone",
                    validation: Yup.string().default(""),
                },
                {
                    type: Input,
                    label: "Geburtstag",
                    name: "birthday",
                    mask: {
                        mask: Date,
                        min: new Date(1900, 0, 1),
                        max: new Date(today),
                        lazy: false,
                    },
                    submitKey: "customer_data.birthday",
                    submitTransform: dayTransform,
                    validation: Yup.date()
                        .format("DD.MM.YYYY", true)
                        .default("")
                        .typeError("Ungültiges Datumsformat")
                        .required("Bitte ausfüllen")
                        .test(
                            'legal-age',
                            (d) => `Sie müssen mindestens 18 Jahre alt sein`,
                            (value) => {
                                const diff = Date.now() - value.getTime();
                                const ageDate = new Date(diff);
                                let age = Math.abs(ageDate.getUTCFullYear() - 1970);
                                return age > 18;
                            }
                        )
                },
            ],
        },
        {
            title: "Angaben zur neuen Verbrauchsstelle",
            fields: [
                {
                    type: Address,
                    name: "shippingAddress",
                    submitKey: "address",
                    summary: AddressSummary,
                    label: "Neue Verbrauchsstelle",
                    options: {
                        required: true
                    },
                    validation: Yup.object().test(
                        "addressRequired",
                        ({value}) => {
                            return {
                                city: !value?.city.length,
                                postcode: !value?.postcode.length,
                                number: !value?.number.length,
                                street: !value?.street.length,
                                message: "Bitte ausfüllen"
                            }
                        },
                        (value) => {
                            return value?.city.length > 0 && value?.postcode.length > 0 && value?.number.length > 0 && value?.street.length > 0;
                        }
                    ).test('zipCodeFromHerne',({value}) => {
                        return {
                            postcode: !hernePostcodes.includes(parseInt(value?.postcode)),
                            message: "Postleitzahl muss aus Herne sein"
                        }
                    }, (value) => {
                        return  hernePostcodes.includes(parseInt(value?.postcode))
                    }).nullable()
                },
                {
                    type: Input,
                    name: "pre_tenant",
                    label: "Vornutzer (falls bekannt)",
                    description: "Der Name Ihres Vornutzers (falls bekannt)",
                    validation: Yup.string().default(""),
                },
                {
                    type: Input,
                    label: "Anmeldedatum",
                    name: "registration_date",
                    mask: {
                        mask: Date,
                        min: new Date(today),
                        // min: new Date(1900, 0, 1),
                        max: new Date(2050,0,0),
                        lazy: false,
                    },
                    submitKey: "customer_data.birthday",
                    submitTransform: dayTransform,
                    validation: Yup.date()
                        .format("DD.MM.YYYY", true)
                        .default("")
                        .typeError("Ungültiges Datumsformat")
                        .required("Bitte ausfüllen"),
                }
            ],
        },
        {
            title: "Zähler",
            description:
                "Die Zählerinformationen helfen uns, Ihren Anschluss schnell zuzuordnen",
            fields: [
                {
                    type: Input,
                    label: "Zählernummer",
                    name: "meterNumber",
                    submitKey: "meter_number",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Zählerstand (nur die Zahlen vor dem Komma)",
                    name: "meterReading",
                    submitKey: "meter_reading",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: MetaText,
                    submitKey: "more2",
                },
                {
                    type: MetaText,
                    submitKey: "more3",
                },
                {
                    type: Switch,
                    label: "Weitere Zählerstände melden",
                    name: "moremeterreadings",
                    submitKey: "more",
                    summary: YesNo,
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings,
                    label: "Zählernummer",
                    name: "moreMeterNumber",
                    submitKey: "more.meter_number",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings,
                    label: "Zählerstand (nur die Zahlen vor dem Komma)",
                    name: "moreMeterReading",
                    submitKey: "more.meter_reading",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Switch,
                    conditions: (values) => values.moremeterreadings,
                    label: "Weitere Zählerstände melden",
                    name: "moremeterreadings2",
                    submitKey: "more2",
                    summary: YesNo,
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings2,
                    label: "Zählernummer",
                    name: "meterNumber2",
                    submitKey: "more2.meter_number2",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings2,
                    label: "Zählerstand (nur die Zahlen vor dem Komma)",
                    name: "meterReading2",
                    submitKey: "more2.meter_reading2",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Switch,
                    conditions: (values) => values.moremeterreadings2,
                    label: "Weitere Zählerstände melden",
                    name: "moremeterreadings3",
                    submitKey: "more3",
                    summary: YesNo,
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings3,
                    label: "Zählernummer",
                    name: "meterNumber3",
                    submitKey: "more3.meter_number3",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings3,
                    label: "Zählerstand (nur die Zahlen vor dem Komma)",
                    name: "meterReading3",
                    submitKey: "more3.meter_reading3",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                }
            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "privacyPolicy",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
                {
                    type: MetaText,
                    name: 'customerConstract',
                    label: 'Allgemeine Geschäftsbedingungen',
                    description: "Unsere Allgemeinen Geschäftsbedingungen finden Sie " + "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>hier</a>",
                },
                {
                    type: Switch,
                    name: 'customerDisclaimer',
                    label: 'Widerrufsbelehrung',
                    submitKey: ['privacy_data.isCustomerDisclaimerAccept'],
                    description: "Ich habe die Widerrufsbelehrung zur Kenntnis genommen und akzeptiere diese. Meinen schriftlichen Widerruf kann ich über das "  + "<a href=\'/PDFs/Bestellung/Widerrufsformular.pdf\' target=\'_blank\'>Widerrufsformular</a>. " + "an die Stadtwerke Herne senden.",
                    validation: Yup.boolean()
                        .oneOf([true], 'Bitte akzeptieren Sie die Widerrufsbelehrung')
                        .required('Bitte akzeptieren Sie die Widerrufsbelehrung')
                },

            ],
        },
    ],
    submitUrl: "/api/form",
};
