import currency from "currency.js";

export function moneyFormat(value) {
    return currency(value, {
        decimal: ',',
        separator: '.',
        symbol: '€',
        pattern: `# !`
    }).format();
}

export function centFormat(value) {
    return currency(value * 100, {
        decimal: ',',
        separator: '.',
        symbol: 'ct',
        pattern: `# !`
    }).format();
}
