<template>
    <div>
        <div
            class="w-fit text-center rounded-t-lg overflow-hidden bg-red-600 text-white font-bold text-xl xl:text-3xl py-3 px-8">
            Tarifrechner
        </div>
        <div class="w-full lg:min-h-[140px] shadow-md rounded-b-lg rounded-tr-lg bg-white text-gray-500 border">
            <div class="flex flex-col 2xl:flex-row 2xl:h-[140px]">
                <div class="flex 2xl:flex-col w-full 2xl:w-fit border-b lg:border-r">
                    <button v-if="computedTypes.includes('electricity')" @click="selectedType = 'electricity'"
                            class="font-bold w-1/2 lg:w-full text-center lg:text-left py-4 px-6 md:px-12 text-xl flex grow items-center transition-all"
                            :class="selectedType === 'electricity' ? 'bg-white text-red-600' : 'bg-gray-100 text-black'"
                    >
                        <i class="icon-stwh_Icons_-31 text-3xl"></i><span class="mt-2 ml-2">Strom</span>
                    </button>
                    <button v-if="computedTypes.includes('carpower')" @click="selectedType = 'carpower'"
                            class="font-bold w-1/2 lg:w-full text-center lg:text-left py-4 px-6 md:px-12 text-xl flex grow items-center transition-all"
                            :class="selectedType === 'carpower' ? 'bg-white text-red-600' : 'bg-gray-100 text-black'"
                    >
                        <i class="icon-stwh_Icons_-40 text-4xl"></i><span class="mt-2 ml-2">Auto-Strom</span>
                    </button>
                    <button v-if="computedTypes.includes('gas')" @click="selectedType = 'gas'"
                            class="font-bold w-1/2 lg:w-full text-center lg:text-left py-4 px-6 md:px-12 text-xl flex grow items-center transition-all"
                            :class="selectedType === 'gas' ? 'bg-white text-red-600' : 'bg-gray-100 text-black'"
                    >
                        <i class="icon-stwh_Icons_-23 text-3xl"></i><span class="mt-2 ml-2">Gas</span>
                    </button>
                </div>
                <div
                    class="flex flex-col xl:space-x-4 space-y-4 xl:space-y-0 lg:flex-row flex-wrap xl:flex-nowrap p-4 justify-between grow items-center min-h-full">
                    <div
                        class="flex flex-col lg:flex-row w-full items-end justify-between lg:space-x-4 space-y-4 xl:space-y-0 ">
                        <Zip v-model:zip="zip" v-model="addressData" label="Ihre Postleitzahl" class="w-full"></Zip>
                        <Input label="Ihre Stadt" v-model="city" :valid="!!city" class="w-full" disabled/>
                        <ConsumptionPicker v-model="consumption"
                                           :subtype="selectedType" class="w-full"
                                           label="Ihr Verbrauch"></ConsumptionPicker>
                    </div>
                    <div
                        class="flex flex-col lg:flex-row lg:justify-around xl:justify-start w-full xl:w-fit items-center mx-auto xl:mx-0 lg:space-x-4 space-y-4 xl:space-y-0 ">
                        <div v-if="showSwitch" class="flex flex-wrap justify-center lg:flex-col space-y-2">
                            <Switch class="mx-2 xl:mx-0" v-model="bonuswelten" label="Bonuswelten"></Switch>
                            <Switch class="mx-2 xl:mx-0" v-show="selectedType !== 'gas'" v-model="eco"
                                    label="Öko-Tarif"></Switch>
                        </div>
                        <Button :href="href" :inset="false" glow :disabled="!isButtonEnabled">Tarif finden
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from 'vue';
import Zip from "./fields/Zip";
import Input from "./Input";
import ConsumptionPicker from "./ConsumptionPicker";
import Switch from "./fields/Switch";
import Button from "./Button";
import {debouncedWatch} from "@vueuse/core";
const props = defineProps({
    types: {
        type: String,
        default: "both"
    },
    products: {
        type: Array,
        default: []
    },
    customerType: {
        type: String,
    },
    resultsLink: {
        type: String
    },
    showSwitch: {
        type: Boolean,
        default: true
    },
    fallbackLink: {
        type: String,
        default: '/'
    }
})

const computedTypes = computed(() => {
    if (!props.types || props.types === 'both') {
        return ['electricity', 'gas']
    }

    return [props.types];
})


const selectedType = ref(computedTypes.value[0]);

const addressData = ref({});
const zip = ref('')
const zipIsValid = ref(null);
const consumption = ref(1000);
const eco = ref(false);
const bonuswelten = ref(true);
const city = computed(() => addressData?.value?.city)




const href = computed(() => {
    if (!addressData.value) {
        return null
    }

    if (zipIsValid.value === true) {
        return props.resultsLink + `?type=${selectedType.value}&consumption=${consumption.value}&postcode=${addressData.value.postcode}&eco=${eco.value}&bonuswelten=${bonuswelten.value}&business=${props.customerType}&products=${props.products.join(',')}`
    }

    const evnType = selectedType.value === 'electricity' || 'carpower' ? 'strom' : 'gas';
    return props.fallbackLink;

})

const isButtonEnabled = computed(() => {
    return href.value
})

debouncedWatch(
    zip,
    () => {
        if (zip.value.length === 5) {
            fetch(`/api/check-postcode/${zip.value}`).then(response => response.json()).then(({valid}) => {
                zipIsValid.value = valid;
            });
        }
    },
    {
        debounce: 200,
        immediate: true
    }
)

</script>
