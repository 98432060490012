<template>
    <DatePicker v-model="data" mode="date" color="red" class="z-auto">
        <template class="z-0" v-slot="{ inputValue, togglePopover }">
            <Input
                class="z-0"
                :value="inputValue"
                @focus="togglePopover()"
                v-bind="$attrs"
            />
        </template>
    </DatePicker>
</template>

<script setup>
import { DatePicker } from 'v-calendar';
import {computed} from 'vue';
import Input from "../Input";
import { useVModel } from '@vueuse/core'
import dayjs from "dayjs";
import 'v-calendar/dist/style.css';

const props = defineProps({
    modelValue: String,
});

const emit = defineEmits(['update:modelValue'])
const model = useVModel(props, 'modelValue', emit)
const data = computed({
    get() {
        return dayjs(model.value, 'DD.MM.YYYY').toDate()
    },
    set(date) {
        model.value = dayjs(date).format('DD.MM.YYYY')
    }
})

</script>

<style scoped>

</style>
