import Alpine from 'alpinejs';
import Intersect from '@alpinejs/intersect';
import Swiper from 'swiper/bundle';
import {CountUp} from 'countup.js';
import {createApp} from 'vue';
import TariffCalculator from './checkout/TariffCalculator.vue'
import TariffResults from './checkout/TariffResults.vue'
import FormRenderer from './checkout/FormRenderer.vue'
import Search from './components/Search.vue'
import 'swiper/swiper-bundle.css';
import VueClickAway from "vue3-click-away";
import LazyLoad from "vanilla-lazyload";
import {autoAnimatePlugin} from "@formkit/auto-animate/vue";
import {createPinia} from "pinia";

const pinia = createPinia();

window.Alpine = Alpine;

// Funktionen für die google-Map Cookies
window.activateGoogleMaps = function(permanent = false) {
    const googleMapsScript = document.createElement('script');
    googleMapsScript.setAttribute('src','//maps.google.com/maps/api/js?callback=googleMapsInit&key=' + window.googleMapsApiKey);
    googleMapsScript.setAttribute('defer','defer');
    googleMapsScript.setAttribute('async','async');
    document.head.appendChild(googleMapsScript);
    const mapPlaceholder = document.getElementById("map-placeholder");

    if(mapPlaceholder instanceof HTMLElement) {
        mapPlaceholder.style.display = "none";
    }

    if ( permanent ) {
        setGoogleMapsAllowed();
    } else {
        deleteGoogleMapsAllowed();
    }
}
window.setGoogleMapsAllowed = function() {
    let date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = "googleMapsAllowed=1; " + expires + "; path=/";
}
window.isGoogleMapsAllowed = function() {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; googleMapsAllowed=`);
    if (parts.length === 2) return parts.pop().split(';').shift() === "1";
}
window.deleteGoogleMapsAllowed = function() {
    document.cookie = 'googleMapsAllowed=; Max-Age=-99999999;';
}


Alpine.plugin(Intersect)

Alpine.nextTick(() => {

    if ( isGoogleMapsAllowed() ) {
        window.activateGoogleMaps();
    }

    const vueApps = Array.from(document.getElementsByClassName('vue-wrapper'));
    if (vueApps.length > 0) {
        vueApps.forEach(vueAppElement => {
            const app = createApp({
                template: vueAppElement.innerHTML
            })
            app.component('tariffcalculator', TariffCalculator)
            app.component('tariffresults', TariffResults)
            app.component('formrenderer', FormRenderer)
            app.component('search', Search)
            app.use(VueClickAway);
            app.use(pinia)
            app.use(autoAnimatePlugin);
            app.mount(vueAppElement)
        })
    }
    new LazyLoad({});

    Alpine.store('mainMenu', {
        menuOpen: false,
        mobileMenuOpen: false,
        submenuOpen: null,
        mobileSubmenuOpen: null,
        mobileActiveServiceMenuPoint: null,
        mobileSubSubmenuOpen: null,
        subsubmenuOpen: null,
        showModal: null,
        mobileSubSubMenuHeight:0,

        close() {
            this.menuOpen = false;
            this.submenuOpen = null;
            this.subsubmenuOpen = null;
        },

        open() {
            this.menuOpen = true;
            this.submenuOpen = null;
            this.subsubmenuOpen = null;
        },

        openMobileMenu() {
            this.mobileMenuOpen = true;
            this.menuOpen = false;
            this.submenuOpen = null;
            this.subsubmenuOpen = null;
            document.body.classList.add('overflow-hidden');
        },
        closeMobileMenu() {
            this.mobileMenuOpen = false;
            this.submenuOpen = null;
            this.mobileSubmenuOpen = null;
            this.mobileSubSubmenuOpen = null;
            this.subsubmenuOpen = null;
            this.mobileActiveServiceMenuPoint = null;
            document.body.classList.remove('overflow-hidden');
        },
        setMobileActiveServiceMenuPoint(point) {
            this.mobileActiveServiceMenuPoint = point;
            this.submenuOpen = null;
            this.subsubmenuOpen = null;
        },
        getMainHeight(height){
            return height +  this.mobileSubSubMenuHeight;
        },
        openMobileSubSubMenu(subSubMenu, subHeight){
            this.mobileSubSubmenuOpen= this.mobileSubSubmenuOpen !== subSubMenu ? subSubMenu : null;
            this.mobileSubSubMenuHeight = !this.mobileSubSubmenuOpen ? 0 : subHeight
        },
        openSubmenu(submenu) {
            this.menuOpen = true;
            this.submenuOpen = submenu;
            this.subsubmenuOpen = null;
        },
        openSubsubmenu(subsubmenu) {
            this.menuOpen = true;
            this.subsubmenuOpen = subsubmenu;
        },
    })

    Alpine.data('mainMenu', () => ({
        showSizer: false,
        init() {
            Alpine.effect(() => {
                const menuOpen = Alpine.store('mainMenu').menuOpen
                const submenuOpen = Alpine.store('mainMenu').submenuOpen
                const subsubmenuOpen = Alpine.store('mainMenu').subsubmenuOpen
                const mobileMenuOpen = Alpine.store('mainMenu').mobileMenuOpen
                const mobileSubmenuOpen = Alpine.store('mainMenu').mobileSubmenuOpen
                const mobileSubSubmenuOpen = Alpine.store('mainMenu').mobileSubSubmenuOpen
                const mobileActiveServiceMenuPoint = Alpine.store('mainMenu').mobileActiveServiceMenuPoint

                const mainMenuContainer = this.$refs.mainMenuContainer;
                const mainMenuWrapper = this.$refs.mainMenuWrapper;

                this.$nextTick(() => {
                    setTimeout(() => {
                        mainMenuContainer.style.width = 290 + ((!!submenuOpen ? 1 : 0) * 290) + ((!!subsubmenuOpen ? 1 : 0) * 290) + "px";
                    }, 50)
                })
            })
        }
    }))

    Alpine.data('chart', (options = {}) => ({
        renderOnStart: false,
        chart: null,
        async init() {
            const ApexCharts = (await import('apexcharts')).default
            this.chart = new ApexCharts(this.$el, options);
            if (this.renderOnStart) {
                this.renderChart();
            }
        },
        renderChart() {
            this.renderOnStart = true;
            this.chart && this.chart.render();
        }
    }))

    Alpine.data("counter", (counter, options = {}) => ({
        counter,
        options,
        init() {
            this.counter = new CountUp(this.$el, parseFloat(this.counter), this.options);
        },
        start() {
            this.counter.reset();
            this.counter.start();
        }
    }))


    Alpine.start();

    const teaserSlider = new Swiper('.teaserslider', {
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            }
        },
        pagination: {
            el: '.swiper-pagination',
        },
        keyboard: {
            enabled: true
        },
        speed: 2000,
        lazy: {
            loadPrevNext: true,
        },

    });

    const teaserSliderFull = new Swiper('.teaserslider-full', {
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
        },
        keyboard: {
            enabled: true
        },
        speed: 2000,
        lazy: {
            loadPrevNext: true,
        },
    });

    const iconSlider = new Swiper('.iconslider', {
        slidesPerView: 'auto',
        loop: true,
        spaceBetween: 30,
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 50,
            },
        },
        mousewheel: true,
        keyboard: true,
        autoplay: true,
        speed: 1000,
        lazy: {
            loadPrevNext: true,
        },
    });
});

window.initHeroSlider = function (element) {
    Alpine.nextTick(() => {
        setTimeout(() => {
            new Swiper(element, {
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                },
                dynamicBullets: true,
                autoplay: true,
                speed: 2000,
                lazy: {
                    loadPrevNext: true,
                },
            });
        })
    })
}

window.initCounterSlider = function (element) {
    if (window.matchMedia('(max-width:768px)').matches) {
        new Swiper(element, {
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            keyboardControl: true,
            grabCursor: true,
            pagination: {
                el: '.swiper-pagination',
            },
            paginationClickable: true,
            autoplay: true,
            speed: 2200,
            lazy: {
                loadPrevNext: true,
            },
        });
    }
}

window.getAnchorButtons = function () {
    return Array.from(document.getElementsByClassName('anchorHeadline')).map(anchorHeadline => ({
        id: anchorHeadline.getAttribute('id'),
        label: anchorHeadline.innerHTML
    }))
}
