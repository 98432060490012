<template>
    <span v-if="selectedAddons">{{ selectedAddons.length > 0 ? selectedAddons.map(a => a.name).join(', ') : '-' }}</span>
</template>

<script setup>
import {useCheckoutStore} from "../stores/checkout";
import {storeToRefs} from "pinia";

const checkoutStore = useCheckoutStore();

let {selectedAddons} = storeToRefs(checkoutStore)

const props = defineProps({
    value: Array
})
</script>
