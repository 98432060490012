<template>
    <div class="">
        <Listbox v-model="value" :multiple="multiple" v-slot="{ open }">
            <div class="relative mt-1">
                <ListboxButton
                    :class="[
                        open ? 'rounded-t-lg' : 'rounded-lg',
                        props.error && !props.valid ? 'border-red-300' : 'border-gray-300',
                         {'border-green-500 md:border-gray-300':  props.valid && !error },
                    ]"
                    class="relative w-full z-auto bg-white border shadow-sm pr-10 px-8 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    <ListboxLabel class="block bg-white text-xs text-gray-500">{{ props.label }}</ListboxLabel>
                    <span v-if="multiple" :class="value?.length > 0 ? 'text-gray-900' : 'text-gray-500'"
                          class="block w-full border-0 p-0 focus:ring-0 text-sm md:text-lg">{{
                            value?.length > 0 ? value.map(v => v.name).join() : placeholder
                        }}</span>
                    <span v-else :class="value ? 'text-gray-900' : 'text-gray-500'"
                          class="block w-full border-0 p-0 focus:ring-0 text-sm md:text-lg">{{
                            value ? value.name : placeholder
                        }}</span>
                    <span
                        class="absolute inset-y-2 right-2 flex items-center pointer-events-none w-6 md:w-20 justify-center border-l border-gray-300"
                    >
                        <ChevronDownIcon
                            :class="[
                                open ? 'rotate-180' : '',
                                'transition h-5 w-5 text-gray-400'
                            ]"
                            aria-hidden="true"/>
                    </span>
                    <div v-if="props.error"
                         class="absolute transform translate-y-1/2 bg-white bottom-0 left-6 px-2 text-xs text-red-600">
                        {{ props.error }}
                    </div>
                    <CheckIcon v-if="props.valid"
                               class="hidden md:block absolute top-0 right-[6.5rem] transform translate-y-1/2 h-8 w-8 text-green-500 opacity-50"
                               aria-hidden="true"/>
                </ListboxButton>

                <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <ListboxOptions
                        class="absolute border-l border-r border-b border-gray-300 z-40 w-full bg-white shadow-lg max-h-60 rounded-b-lg py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                        <ListboxOption
                            v-slot="{ active, selected }"
                            v-for="option in computedOptions"
                            :key="option.name"
                            :value="option"
                            as="template"
                        >
                            <li
                                :class="[
                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-8 pr-4'
                                ]"
                            >
                                <span
                                    :class="[
                                        selected ? 'font-semibold' : 'font-normal',
                                        'block truncate text-base md:text-lg'
                                  ]"
                                >{{ option.name }}</span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script setup>
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,} from '@headlessui/vue'
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/solid'
import {computed} from "vue";
import { asyncComputed } from '@vueuse/core'

const props = defineProps({
    options: {
        required: true,
        type: Array
    },
    label: String,
    error: String,
    valid: {
        type: Boolean,
        default: false
    },
    name: String,
    placeholder: String,
    modelValue: Object,
    multiple: {
        type: Boolean,
        default: false
    },
});

const emit = defineEmits(['update:modelValue']);

const computedOptions = asyncComputed(async() => {
    return await props.options
}, [])

const value = computed({
    get() {

        if(props.multiple) {
            console.log(props.modelValue)
            return computedOptions.value.filter(o => props.modelValue?.includes(o.value))
        }

        return computedOptions.value?.find(o => o.value === props.modelValue)
    },
    set(value) {
        console.log("set", value)
        if(props.multiple) {
            emit('update:modelValue', value.map(v => v.value))
        } else {
            emit('update:modelValue', value.value)
        }
    }
})


</script>
