<template>
    <div>
        <Input :label="label" :mask="{mask: '00000'}" :valid="error?.length === 0 && value" v-model="zip" :loading="loadingCity" :error="error" ></Input>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import Input from "../Input";
import {debouncedWatch} from "@vueuse/core";

const props = defineProps({
    modelValue: Object,
    zip: String,
    label: String,
    error: String,
})

const emit = defineEmits(['update:modelValue', 'update:zip']);

const loadingCity = ref(false)

const zip = computed({
    get() {
        return props.zip
    },
    set(value) {
        emit('update:zip', value)
    }
})

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

const fetchCity = async (zip) => {
    loadingCity.value = true;
    try {
        const url = `/api/geo/autocomplete?text=${zip}&type=postcode&lang=de&limit=1&filter=countrycode%3Ade`;
        const response = await fetch(url, {
            "method": "GET",
        }).then(response => response.json());
        value.value = response.features.pop()?.properties;
    } catch (e) {
        value.value = null
    } finally {
        loadingCity.value = false;
    }
}

debouncedWatch(
    zip,
    () => {
        if (zip.value?.length === 5) {
          fetchCity(zip.value)
        }
        else {
            value.value = null;
        }
    },
    {
        debounce: 200,
        immediate: true
    }
)
</script>
