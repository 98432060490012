<template>
  {{ additionalProduct?.name }}
</template>

<script setup>
import {useCheckoutStore} from "../stores/checkout";
import {storeToRefs} from "pinia";

const checkoutStore = useCheckoutStore();

let {additionalProduct} = storeToRefs(checkoutStore)

const props = defineProps({
  value: Array
})
</script>

<style scoped>

</style>
