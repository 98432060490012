<template>
    <div>
        <RadioGroup v-model="value" v-if="additionalProducts.length > 0">
            <div class="mt-4" ref="radioWrapper">
                <RadioGroupOption as="template" :value="null" v-slot="{ checked, active }">
                    <div
                        :class="[checked ? 'border-transparent' : 'border-gray-300', active || selectedAdditionalProduct === null ? 'border-red-500 ring-2 ring-red-500' : '', 'bg-gray-100 mb-4 relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                      <span class="flex-1 flex">
                        <span class="flex flex-col">
                          <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">
                            Kein Zusatzprodukt
                          </RadioGroupLabel>
                          <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">
                            Danke, kein Bedarf
                          </RadioGroupDescription>
                        </span>
                      </span>
                        <span
                            :class="[active || selectedAdditionalProduct === null ? 'border' : 'border-2', checked ? 'border-red-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                            aria-hidden="true"/>
                    </div>
                </RadioGroupOption>
                <RadioGroupOption as="template" v-for="product in filteredAdditionalProducts" :key="product.id"
                                  :value="product" v-slot="{ checked, active }">
                    <div
                        :class="[checked ? 'border-transparent shadow-lg' : 'hover:shadow-sm border-gray-300', active ? 'border-red-500 ring-2 ring-red-500' : '', 'mb-4 transition-all relative bg-white border rounded-lg p-4 flex cursor-pointer focus:outline-none']">
                      <span class="flex-1 flex">
                        <span class="flex flex-col">
                          <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">
                            {{ product.name }}
                          </RadioGroupLabel>
<!--                          <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">
                            {{ product.description }}
                          </RadioGroupDescription>-->
                        </span>
                      </span>
                        <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-red-600']"
                                         aria-hidden="true"/>
                        <span
                            :class="[active ? 'border' : 'border-2', checked ? 'border-red-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                            aria-hidden="true"/>
                    </div>
                </RadioGroupOption>
                <div v-if="value">
                    <h3 class="text-center text-2xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">Tarifpreis</h3>
                    <LoadingSpinner v-if="additionalProductPriceDataLoading" class="mx-auto" size="lg"></LoadingSpinner>
                    <div class="h-24 flex items-center justify-center" v-else-if="additionalProductPriceData">
                        <span class="px-3 flex items-start text-3xl md:text-4xl tracking-tight text-gray-900">
                            <span class="font-bold font-system">{{ moneyFormat(monthlyAdditionalProductPrice) }}</span>
                        </span>
                        <span class="text-2xl font-medium text-gray-500">/Monat</span>
                    </div>
                    <div class="flex flex-col px-3 mb-2">
                        <span class=" text-sm text-gray-500"> {{value.description}}</span>
                        <Checklist class="my-2" :items="value.checklist"></Checklist>
                    </div>

                </div>
            </div>
        </RadioGroup>
    </div>
</template>


<script setup>
import {computed, ref, watch} from 'vue'
import {RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue'
import {CheckCircleIcon} from '@heroicons/vue/solid'
import LoadingSpinner from "../LoadingSpinner";
import autoAnimate from "@formkit/auto-animate"
import {moneyFormat} from "../domain/utils";
import {useCheckoutStore} from "../stores/checkout";
import {useFormStore} from "../stores/form";
import {storeToRefs} from "pinia";
import Checklist from "../Checklist";

const checkoutStore = useCheckoutStore();
const formStore = useFormStore()();

let {
  additionalProductPriceData, additionalProductPriceDataLoading,
  additionalProducts,
  consumption,
  product,
    monthlyAdditionalProductPrice
} = storeToRefs(checkoutStore);

const props = defineProps({
    modelValue: {
        type: Number,
        default: null
    },
})


const emit = defineEmits(['update:modelValue']);
const value = computed({
    get() {
        return props.modelValue === null ? null : additionalProducts.value?.find(a => a.id === props.modelValue)
    },
    set(value) {
        if(!formStore.$state.additionalProductConsumption) {
            formStore.$state.additionalProductConsumption = 1000;
        }
        emit('update:modelValue', value?.id || null)
    }
})

watch(product, () => {
    product.value && checkoutStore.fetchAdditionalProducts(product.value.additionalProducts);


}, {
    immediate: true
})
const selectedAdditionalProduct = computed(() => {
    return additionalProducts.value?.find(a => a.id === value.value);
})

const filteredAdditionalProducts = computed(() => !!value.value ? additionalProducts.value?.filter(a => a.id === props.modelValue) : additionalProducts.value)
console.log("filterred", filteredAdditionalProducts)
const radioWrapper = ref();
watch(radioWrapper,() => {
    if(radioWrapper.value) {
        autoAnimate(radioWrapper.value)
    }
})

</script>

<style scoped>

</style>
