import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import SelectSummary from "../summary/Select";
import Switch from "../fields/Switch";
import MetaText from "../MetaText";

dayjs.extend(customParseFormat);

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});


export default {
    groups: [
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Input,
                    label: "Vertragskontonummer",
                    name: "contractnumber",
                    submitKey: "customer_data.contractnumber",
                    placeholder: "Siehe Rechnung",
                    validation: Yup.string()
                        .default("")
                        .matches(/^[0-9]+$/, "Vertragsnummer muss nur aus Ziffern bestehen")
                        .min(10, 'Vertragsnummer muss zehnstellig sein')
                        .max(10, 'Vertragsnummer muss zehnstellig sein')
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Gutscheincode",
                    name: "couponcode",
                    mask: {
                        mask: "000000/00/000000",
                    },
                    placeholder: '000000/00/000000',
                    submitKey: "customer_data.couponcode",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        { value: "female", name: "Frau" },
                        { value: "male", name: "Herr" },
                        { value: "other", name: "Divers" },
                        { value: "spouses", name: "Eheleute" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["female", "male", "other", "spouses"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Titel",
                    name: "title",
                    submitKey: "customer_data.title",
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    submitKey: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, { options }) =>
                                options.context.email === value
                        ),
                },
            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
            ],
        },
    ],
    submitUrl: "/api/form",
};
