<template>
    <div class="grid md:grid-cols-6 gap-4">
        <Zip class="md:col-span-3" :label="labels.postcode" v-model:zip="zip" v-model="city" :error="error?.postcode ? error?.message : ''" />
        <Input class="md:col-span-3" :label="labels.city" readonly :disabled="!isZipValid" :value="city && city.city" :error="error?.city ? error?.message : ''"
               :valid="isZipValid"/>
        <Input class="md:col-span-4" :label="labels.street" :disabled="!isZipValid" :valid="street.length" v-model="street" :error="error?.street ? error?.message : ''" />
        <Input class="md:col-span-2" :label="labels.number" :disabled="!isZipValid" :valid="number.length" v-model="number" :error="error?.number ? ' ' : ''" />
    </div>

</template>

<script setup>
import {computed, reactive, ref, watchEffect} from "vue";
import Zip from "./Zip";
import Input from "../Input";
import { useVModel } from '@vueuse/core'


const props = defineProps({
    modelValue: {
        type: Object,
        default: {}
    },
    labels: Object,
    error: Object,
    options: Object
})

const parsedUrl = new URL(window.location.href);
const postCode = parsedUrl.searchParams.get("postcode")

const emit = defineEmits(['update:modelValue']);

const data = useVModel(props, 'modelValue', emit)

const zip = ref(props.modelValue.postcode || postCode);
const city = ref({})
const street = ref(props.modelValue.street || '')
const number = ref(props.modelValue.number || '')

const isZipValid = computed(() => !!city.value?.city)

const defaultLabels = {
    postcode: 'Postleitzahl' + (props?.options?.required ? '*' : ''),
    city: 'Ort' + (props?.options?.required ? '*' : ''),
    street: 'Straße' + (props?.options?.required ? '*' : ''),
    number: 'Haus-Nr.' + (props?.options?.required ? '*' : ''),
}

const labels = computed(() => ({
    ...defaultLabels,
    ...props.labels
}))

watchEffect(() => {
    if(isZipValid.value) {
        data.value = {
            postcode: city.value.postcode,
            city: city.value.city,
            street: street.value,
            number: number.value,
        }
    }
})
</script>
