import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Address from "../fields/Address";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import MetaText from "../MetaText";
import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import Switch from "../fields/Switch";
import Iban from "../Iban";

dayjs.extend(customParseFormat);
const fourDaysLater = dayjs().add(3, 'day');


Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

const dayTransform = (value) => dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD");

export default {
    groups: [
        {
            title: "Standrohrverleih",
            fields: [
                {
                    type: Select,
                    options: [
                        {value: "yes", name: "Ja"},
                        {value: "no", name: "nein"},
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Stadtwerke Herne Kunde?",
                    name: "alreadycustomer",
                    submitKey: "alreadycustomer",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("no")
                        .oneOf(["yes", "no"], "Ungültiger Wert"),
                },
                {
                    type: Select,
                    options: [
                        {value: "qn6", name: "QN 6"},
                        {value: "qn10", name: "QN 10"},
                        {value: "qn15", name: "QN 15"},
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Standrohrart",
                    name: "selectpipe",
                    submitKey: "selectpipe",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .oneOf(["qn6", "qn10", "qn15"], "Ungültiger Wert"),
                },
                {
                    type: Input,
                    label: 'Abholtag',
                    size: "half",
                    name: 'pickupdate',
                    mask: {
                        mask: Date,
                        min: new Date(fourDaysLater),
                        max: new Date(2050, 0, 0),
                        lazy: false
                    },
                    submitKey: 'customer_data.pickupdate',
                    submitTransform: dayTransform,
                    validation: Yup.date()
                        .format('DD.MM.YYYY', true)
                        .default('')
                        .typeError('Mindestens 4 Werktage im Voraus')
                        .required('Bitte ausfüllen'),
                },
                {
                    type: Select,
                    options: [
                        {value: "08:00", name: "08:00"},
                        {value: "08:05", name: "08:05"},
                        {value: "08:10", name: "08:10"},
                        {value: "08:15", name: "08:15"},
                        {value: "08:20", name: "08:20"},
                        {value: "08:25", name: "08:25"},
                        {value: "08:30", name: "08:30"},
                        {value: "08:35", name: "08:35"},
                        {value: "08:40", name: "08:40"},
                        {value: "08:45", name: "08:45"},
                        {value: "08:50", name: "08:50"},
                        {value: "08:55", name: "08:55"},
                        {value: "09:00", name: "09:00"},
                        {value: "09:05", name: "09:05"},
                        {value: "09:10", name: "09:10"},
                        {value: "09:15", name: "09:15"},
                        {value: "09:20", name: "09:20"},
                        {value: "09:25", name: "09:25"},
                        {value: "09:30", name: "09:30"},
                        {value: "09:35", name: "09:35"},
                        {value: "09:40", name: "09:40"},
                        {value: "09:45", name: "09:45"},
                        {value: "09:50", name: "09:50"},
                        {value: "09:55", name: "09:55"},
                        {value: "10:00", name: "10:00"},
                    ],
                    placeholder: "Uhrzeit",
                    label: "Abholzeit",
                    name: "until",
                    size: "half",
                    submitKey: "annotation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .oneOf(
                            [
                                "08:00",
                                "08:05",
                                "08:10",
                                "08:15",
                                "08:20",
                                "08:25",
                                "08:30",
                                "08:35",
                                "08:40",
                                "08:45",
                                "08:50",
                                "08:55",
                                "09:00",
                                "09:05",
                                "09:10",
                                "09:15",
                                "09:20",
                                "09:25",
                                "09:30",
                                "09:35",
                                "09:40",
                                "09:45",
                                "09:50",
                                "09:55",
                                "10:00",
                            ],
                            "Ungültiger Wert"
                        )
                        .required("Bitte ausfüllen"),
                },
            ],
        },
        {
            title: "Kontaktdaten",
            description:
                "Wir behandeln Ihre Kontaktdaten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Select,
                    options: [
                        {value: "female", name: "Frau"},
                        {value: "male", name: "Herr"},
                        {value: "other", name: "Divers"},
                        {value: "spouses", name: "Eheleute"},
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["female", "male", "other", "spouses"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Firma (falls vorhanden)",
                    name: "company",
                    submitKey: "customer_data.company",
                    validation: Yup.string().default(""),
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Address,
                    name: "shippingAddress",
                    submitKey: "address",
                    summary: AddressSummary,
                    label: "Kontaktadresse",
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "customer_data.phone",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    submitKey: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, {options}) =>
                                options.context.email === value
                        ),
                },
                {
                    type: MetaText,
                    description:
                        "Die IBAN wird von uns benötigt, um Ihnen die Kaution nach der Rückgabe des Standrohres wieder zurück zu überweisen.",
                },
                {
                    type: Iban,
                    label: "IBAN",
                    name: "iban",
                    mask: {
                        mask: "aa00 0000 0000 0000 0000 0000 0000 0000 00",
                    },
                    submitKey: "payment_data.iban",
                    placeholder: "DEXX XXXX XXXX XXXX XXXX XX",
                    validation: Yup.string().required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: 'BIC',
                    name: 'bic',
                    submitKey: 'payment_data.bic',
                    placeholder: "XXXX XX XX XXX ",
                    conditions: (values) => values.paymentType === 'sepa',
                    validation: Yup.string()
                        .default('')
                },
            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
            ],
        },
    ],
    submitUrl: "/api/form",
};
