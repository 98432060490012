<template>
    <div>
        <Input v-model="value" v-bind="{...$props, ...$attrs}"></Input>
        <div class="px-4 py-5 sm:p-0" v-if="bankData">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                        Bank
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ bankData.name }}
                    </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                        Ort
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ bankData.city }}
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script setup>
import {CheckIcon} from '@heroicons/vue/solid'
import {computed, onMounted, ref} from "vue";
import {IMask} from 'vue-imask';
import Input from "./Input";
import {useFormStore} from "./stores/form";


const props = defineProps({
    modelValue: String,
    bic: String
})

const emit = defineEmits(['update:modelValue', 'setBic']);

const input = ref(null)
const bankData = ref()
const formStore = useFormStore(props.name);

const fetchBankInformation = async (iban) => {
    console.log("bic", props.bic, props.modelValue)
    const response = await fetch(`https://openiban.com/validate/${iban}?validateBankCode=true&getBIC=true`).then(response => response.json())
    if(response.valid) {
        bankData.value = response.bankData
    }
    else {
        bankData.value = null;
    }
    emit('setBic',  response)
}

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
        fetchBankInformation(value);

    }
})

if(value.value) {
    fetchBankInformation(value.value)
}
</script>
