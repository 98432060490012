import {defineStore} from "pinia";
import {useFormStore} from "./form";

let formStore = null;

export const loadFormStore = () => {
    formStore = useFormStore("checkout")()
}

export const useCheckoutStore = defineStore("checkout", {
    state: () => ({
        priceDataLoading: false,
        products: [],
        product: null,
        business: null,
        eco: null,
        bonuswelt: null,
        postcode: null,
        type: null,
        consumption: 1000,
        priceDataResponse: null,
        productAddons: null,
        additionalProducts: [],
        selectedAddons: [],
        additionalProductPriceDataLoading: false,
        additionalProductPriceData: null,
        additionalProductAddonProduct: [],
        selectedAdditionalProductAddons: [],
        assets: [],
    }),
    getters: {
        selectedAddons() {
            formStore = useFormStore("checkout")()
            return (formStore?.$state.addons || []).map(addonId => this.productAddons?.find(a => a.id === addonId));
        },
        additionalProduct() {
            formStore = useFormStore("checkout")();
            return this.additionalProducts.find(p => p.id === formStore?.$state.additionalProduct);
        },
        monthlyAdditionalProductPrice() {
            formStore = useFormStore("checkout")()
            return (this.additionalProductPriceData?.basePrice + this.additionalProductPriceData?.unitPrice * (formStore?.$state.additionalProductConsumption || 0) / 12)
        },
        additionalProductConsumption() {
            formStore = useFormStore("checkout")()
            return formStore?.$state.additionalProductConsumption;
        },
        additionalProductAddons() {
            return this.additionalProduct?.addOns
        },
        selectedAdditionalProductAddons() {
            formStore = useFormStore("checkout")()
            return (formStore?.$state.additionalProductAddonProduct || []).map(addonId => this.additionalProductAddons?.find(a => a.id === addonId));
        }
    },
    actions: {
        async fetchPriceData() {
            formStore = useFormStore("checkout")()
            this.priceDataLoading = true;
            try {
                if (this.product) {
                    this.priceDataResponse = await fetch(`/api/product/calculate/${this.product.id}?consumption=${this.consumption}&addOns=` + formStore?.$state?.addons?.join(','))
                        .then(response => response.json());
                } else if (this.postcode !== undefined) {
                    this.priceDataResponse = await fetch('/api/products/calculate', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "addOns": (formStore?.$state || []).addons,
                            "consumption": this.consumption,
                            "postcode": this.postcode,
                            "type": this.type,
                            "products": this.products ?? this.product.id,
                            "business": this.business,
                            "eco": this.eco,
                            "bonuswelt": this.bonuswelt,
                            "assets": this.assets,
                        })
                    }).then(response => response.json());
                }
            } finally {
                this.priceDataLoading = false;
            }
        },
        async fetchAdditionalProductPriceData() {
            this.additionalProductPriceDataLoading = true;
            try {
                if (formStore?.$state.additionalProduct) {
                    this.additionalProductPriceData = await fetch(`/api/product/calculate/${formStore.$state.additionalProduct}?consumption=${formStore?.$state.additionalProductConsumption || 0}&addOns=` + formStore?.$state?.additionalProductAddonProduct?.join(','))
                        .then(response => response.json());
                }
            } finally {
                this.additionalProductPriceDataLoading = false;
            }
        },
        async fetchProduct(productId) {
            this.product = await fetch(`/api/products/${productId}`).then(response => response.json());
            this.productAddons = this.product.addOns.map((a) => a);
            this.assets = this.product.assets.map((a) => a);
        },
        async fetchAdditionalProducts(productIds) {
            this.additionalProducts = await Promise.all(productIds.map(productId => fetch(`/api/products/${productId}`).then(response => response.json())));
        }
    }
})
